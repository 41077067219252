let sampleJSON = {
  newApplication : true,
  statusMap : {
    '1' : {name : 'Active', link : '/dashboard/active'},
    '2' : {name : 'Field Visit', link : '/dashboard/active'},
    '3' : {name : 'Approved', link : '/dashboard/active'},
    '4' : {name : 'Delivered', link : '/dashboard/active'},
    '5' : {name : 'Discarded', link : '/dashboard/active'},
    '6' : {name : 'Rejected', link : '/dashboard/active'},
  },
  asideBarStatusList : [ ],
  documentLinkList: [0, 1],
}
export {sampleJSON};