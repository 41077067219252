import { Capacitor } from "@capacitor/core";
import { File as NativeFile, DirectoryEntry } from "@ionic-native/file";
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions, CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture';
export async function mediaFileToFile (media : MediaFile, callback : Function) {
  let resolvedPath: DirectoryEntry;
  let path = media.fullPath.substring(0, media.fullPath.lastIndexOf("/"));
  if (Capacitor.getPlatform() === "ios") {
    resolvedPath = await NativeFile.resolveDirectoryUrl("file://" + path);
  } else {
    resolvedPath = await NativeFile.resolveDirectoryUrl(path);
  }
  return NativeFile.readAsArrayBuffer(resolvedPath.nativeURL, media.name).then(
    (buffer: any) => {
      // get the buffer and make a blob to be saved
      let blob = new Blob([buffer], {
        type: media.type,
      });
      callback(blob);
    },
    (error: any) => console.log(error)
  )
}
export async function mediaPathToFile (inPath : string, callback : Function) {
  let resolvedPath: DirectoryEntry;
  let path = inPath.substring(0, inPath.lastIndexOf("/"));
  let filename = inPath.split("/").pop();
  console.log(filename);
  if (Capacitor.getPlatform() === "ios") {
    resolvedPath = await NativeFile.resolveDirectoryUrl("file://" + path);
  } else {
    resolvedPath = await NativeFile.resolveDirectoryUrl(path);
  }
  return NativeFile.readAsArrayBuffer(path, filename ? filename : "").then(
    (buffer: any) => {
      // get the buffer and make a blob to be saved
      let blob = new Blob([buffer]);
      callback(blob);
    },
    (error: any) => console.log("y"+JSON.stringify(error))
  )
}