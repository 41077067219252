import axios, { AxiosResponse } from 'axios';
import { MasterInit } from '../../../_metronic/layout/MasterInit';
import { FC, useEffect, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { Dashboard } from '../../modules/models/Dashboard';
import { HeaderWrapper } from '../../../_metronic/layout/components/header/HeaderWrapper';
import { AsideDefault } from '../../../_metronic/layout/components/aside/AsideDefault';

const Dashboard1: FC = () => {
  type ApiParams = { status: string; };
  const { status } = useParams<ApiParams>();
  let [pageStructure, setPageStructure] = useState(new Dashboard());
  let [applicationList, setApplicationList] = useState([]);
  const createLoanApplication = () => {
    axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/', {//Url
      headers: {
        'Content-Type': "application/json"
      }
    }).then((reply) => {//Errors come in status 200
      console.log(reply)
      if (reply?.data?.applicationId) {
        window.location.pathname = '/loan-application/' + reply?.data?.applicationId;
      }
    }, (error) => {
      //reject(error)
    });
  }
  useEffect(() => {
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/dashboard/' + status, {//Url
      headers: {
        'Content-Type': "application/json"
      }
    }).then((reply) => {//Errors come in status 200
      if (reply?.data?.data?.page) {
        setPageStructure(new Dashboard(reply.data.data.page));
        setApplicationList(reply.data.data.applicationList);
      }
    }, (error) => {
      //reject(error)
    });
  }, [status]);
  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      {pageStructure.asideBarStatusList.length > 0 && <AsideDefault menuItems={pageStructure.asideBarStatusList.map((status: number) => {
        if (status == 0) return { 'All': '/dashboard/0' }
        return { [pageStructure.statusMap[status.toString()].name]: pageStructure.statusMap[status.toString()].link }
      })
      } />}
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper' style={{paddingTop : 0}}>
        <HeaderWrapper />

        <div id='kt_content' className='p-4'>
          <div className='post' id='kt_post'>
          {pageStructure.newApplication && <button type='button' className='btn btn-primary p-3 m-3' onClick={createLoanApplication}>New Application</button>}
            {applicationList.map((application : any) => {
              return (<div className='m-3' style={{ width: "100%" }}>
                <div className="card card-custom card-flush m-2">
                  <div className="card-header">
                    <h3 className="card-title">{application.borrowerName && application.borrowerName != '' ?  application.borrowerName : 'Loan Application'}</h3>
                    <div className="card-toolbar">
                      <span className="btn-sm btn-success" style={{color : "white"}}>
                      {pageStructure.statusMap[application.status].name}
                      </span>
                    </div>
                  </div>
                  {application.status != 6 &&<div >
                    {pageStructure.documentLinkList.map((link: any) => {
                      if (link == '0') {
                        return (
                          <a href={'/loan-application/' + application._id + '/0'} className="btn btn-sm btn-light float-end m-1">
                            Loan Application
                          </a>
                        )
                      }
                      if (link == '1') {
                        return (
                          <a href={'/loan-disbursement/' + application._id + '/0'} className="btn btn-sm btn-light float-end m-1">
                            Loan Disbursement
                          </a>
                        )
                      }
                      if (link == '2') {
                        return (
                          <a href={'/field-visit/' + application._id + '/0'} className="btn btn-sm btn-light float-end m-1">
                            Field Visit
                          </a>
                        )
                      }
                      if (link == '4') {
                        return (
                          <a href={'/signature/' + application._id + '/0'} className="btn btn-sm btn-light float-end m-1">
                            Signature
                          </a>
                        )
                      }
                    })
                    }
                  </div>}
                  {application.status == 6 &&<div >
                    {pageStructure.documentLinkList.map((link: any) => {
                      if (link == '3') {
                        return (
                          <a href={'/reopen-application/' + application._id + '/0'} className="btn btn-sm btn-light float-end m-1">
                            Re-open Application
                          </a>
                        )
                      }
                    })
                    }
                  </div>}
                </div>
              </div>)
            }
            )}
          </div>
        </div>

      </div>
    </div >
  )
}
export { Dashboard1 }