/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { StepperComponent } from '../../../_metronic/assets/ts/components'

interface ICreateAccount {
  [key: string]: any
}

const inits: ICreateAccount = {
  email: 'rajivkumar@rajiv.com',
  contact: '8989898989',
  fathername: 'Amit Kumar',
  line1: '2-3 MG Road Karol Bagh',
  city: 'Delhi',
  state: 'delhi',
  district: 'delhi',
  pincode: '110001',
  nameOnCard: 'Max Doe',
  cardNumber: '4111 1111 1111 1111',
  cardExpiryMonth: '1',
  cardExpiryYear: '2025',
  cardCvv: '123',
  saveCard: '1',
}

const createAppSchema = [
  Yup.object({
    idType: Yup.string().required().label('Id Type'),
    idNumber: Yup.string().required().label('Id Number'),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
  Yup.object({
    framework: Yup.string().required().label('Framework'),
  }),
  Yup.object({
    dbName: Yup.string().required().label('Database name'),
    dbType: Yup.string().required().label('Database engine'),
  }),
]

const Form1: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateAccount>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      //stepper.current.goto(1)
      //actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div style={{ background: 'white', padding: "10px" }}>

      <img src='https://www.nextias.com/website_assets/images/logo.png' style={{ position: "absolute" }} />
      <div className='py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px mt-20'>
            <div className='stepper-nav ps-lg-10'>
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>Details</h3>
                </div>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>Payment</h3>
                </div>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>Confirmation</h3>
                </div>
              </div>
            </div>
          </div>

          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <h2 className='text-center' style={{ padding: "20px" }}>Registration Form</h2>
            <Formik
              enableReinitialize={true}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({ errors, touched }) => (
                <Form className='form' noValidate id='kt_modal_create_app_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>ID Card Type</span>
                        </label>

                        <Field
                          as='select'
                          className='form-control form-control-lg form-control-solid'
                          name='idType'
                          placeholder=''
                        >
                          <option value=''>--Select--</option>
                          <option value='aadhaar'>Aadhaar</option>
                          <option value='dl'>Driving License</option>
                          <option value='voterId'>Voter Id</option>
                        </Field>

                        { errors.idType && <div className='text-danger'>{errors.idType}</div>}

                      </div>
                      <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Id Number</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='idNumber'
                          placeholder=''
                        />
                        {errors.idNumber && <div className='text-danger'>{errors.idNumber}</div>}

                      </div>
                      <div className='rounded bg-secondary p-2'>
                        <h4 className='text-center'>Course Details</h4>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Session</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='session'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>2023-24</option>
                            <option value='dl'>2022-23</option>
                            <option value='voterId'>2021-22</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Center</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='center'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>Delhi</option>
                            <option value='dl'>Bhopal</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Course</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='course'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>AAAAA</option>
                            <option value='dl'>BBBBB</option>
                            <option value='voterId'>CCCCC</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Batch Type</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='batchType'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>AAAAA</option>
                            <option value='dl'>BBBBB</option>
                            <option value='voterId'>CCCCC</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Exam</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='exam'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>AAAAA</option>
                            <option value='dl'>BBBBB</option>
                            <option value='voterId'>CCCCC</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Stream</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='stream'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>AAAAA</option>
                            <option value='dl'>BBBBB</option>
                            <option value='voterId'>CCCCC</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Batch</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='batch'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>AAAAA</option>
                            <option value='dl'>BBBBB</option>
                            <option value='voterId'>CCCCC</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='pb-10 pb-lg-15'>
                        <h2 className='fw-bolder text-dark'>Billing Details</h2>

                        <div className='text-gray-400 fw-bold fs-6'>
                          If you need more info, please check out
                          <a href='#' className='text-primary fw-bolder'>
                            Help Page
                          </a>
                          .
                        </div>
                      </div>
                      <div className='d-flex flex-column mb-7 fv-row'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Name On Card</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title="Specify a card holder's name"
                          ></i>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='nameOnCard'
                        />
                        <div className='fv-plugins-message-container invalid-feedback'>
                          <ErrorMessage name='nameOnCard' />
                        </div>
                      </div>
                      <div className='d-flex flex-column mb-7 fv-row'>
                        <label className='required fs-6 fw-bold form-label mb-2'>
                          Card Number
                        </label>

                        <div className='position-relative'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Enter card number'
                            name='cardNumber'
                          />
                          <div className='fv-plugins-message-container invalid-feedback'>
                            <ErrorMessage name='cardNumber' />
                          </div>

                          <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                            <img
                              src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                              alt=''
                              className='h-25px'
                            />
                            <img
                              src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                              alt=''
                              className='h-25px'
                            />
                            <img
                              src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                              alt=''
                              className='h-25px'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <div className='col-md-8 fv-row'>
                          <label className='required fs-6 fw-bold form-label mb-2'>
                            Expiration Date
                          </label>

                          <div className='row fv-row'>
                            <div className='col-6'>
                              <Field
                                as='select'
                                name='cardExpiryMonth'
                                className='form-select form-select-solid'
                              >
                                <option></option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                                <option value='11'>11</option>
                                <option value='12'>12</option>
                              </Field>
                              <div className='fv-plugins-message-container invalid-feedback'>
                                <ErrorMessage name='cardExpiryMonth' />
                              </div>
                            </div>

                            <div className='col-6'>
                              <Field
                                as='select'
                                name='cardExpiryYear'
                                className='form-select form-select-solid'
                              >
                                <option></option>
                                <option value='2021'>2021</option>
                                <option value='2022'>2022</option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                                <option value='2028'>2028</option>
                                <option value='2029'>2029</option>
                                <option value='2030'>2030</option>
                                <option value='2031'>2031</option>
                              </Field>
                              <div className='fv-plugins-message-container invalid-feedback'>
                                <ErrorMessage name='cardExpiryYear' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-4 fv-row'>
                          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                            <span className='required'>CVV</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Enter a card CVV code'
                            ></i>
                          </label>

                          <div className='position-relative'>
                            <Field
                              type='text'
                              className='form-control form-control-solid'
                              placeholder='CVV'
                              name='cardCvv'
                            />
                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='cardCvv' />
                            </div>

                            <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                              <KTSVG
                                path='/media/icons/duotune/finance/fin002.svg'
                                className='svg-icon-2hx'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-stack'>
                        <div className='me-5'>
                          <label className='fs-6 fw-bold form-label'>
                            Save Card for further billing?
                          </label>
                          <div className='fs-7 fw-bold text-gray-400'>
                            If you need more info, please check budget planning
                          </div>
                        </div>

                        <label className='form-check form-switch form-check-custom form-check-solid'>
                          <Field className='form-check-input' type='checkbox' />
                          <span className='form-check-label fw-bold text-gray-400'>
                            Save Card
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='rounded bg-secondary p-2'>
                        <h4 className='text-center'>Registration Details</h4>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Candidate Name</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg '
                            name='name'
                            placeholder='Candidate Name'
                            value='Rajiv Kumar'
                            disabled
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Date of Birth</span>
                          </label>

                          <Field
                            type="date"
                            className='form-control form-control-lg '
                            name='dob'
                            placeholder=''
                            value='1992-02-29'
                            disabled
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Gender</span>
                          </label>

                          <div className="radio-inline">
                            <label className="radio m-2">
                              <input type="radio" name="gender" value="male" checked disabled/>
                              <span className='p-1'></span>
                              Male
                            </label>
                            <label className="radio m-2">
                              <input type="radio" name="gender" value="female" disabled/>
                              <span className='p-1'></span>
                              Female
                            </label>
                          </div>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Category</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg'
                            name='category'
                            placeholder=''
                            value='general'
                            disabled
                          >
                            <option value=''>--Select--</option>
                            <option value='general'>General</option>
                            <option value='dl'>SC</option>
                            <option value='voterId'>ST</option>
                            <option value='voterId'>OBC</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Email Id</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='email'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Confirm Email Id</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='confirmemail'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Contact Number</span>
                          </label>

                          +91<Field
                            type='text'
                            className='form-control form-control-lg form-control-solid d-inline-block'
                            name='contact'
                            placeholder=''
                            style={{ marginLeft: "10px", width: "80%" }}
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <input
                            type='checkbox'
                            name='handicapped'
                            placeholder=''
                          >
                          </input>
                          <label className='align-items-center fs-5 fw-bold mb-2'>
                            <span >Handicapped(Physically Disabled)</span>
                          </label>
                        </div>
                      </div>
                      <div className='rounded bg-secondary p-2 mt-4'>
                        <h4 className='text-center'>Father/Guardian Details</h4>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Father's Name</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='fathername'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Occupation</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='fatheroccupation'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Mobile No.</span>
                          </label>

                          +91<Field
                            type='text'
                            className='form-control form-control-lg form-control-solid d-inline-block'
                            name='fathercontact'
                            placeholder=''
                            style={{ marginLeft: "10px", width: "80%" }}
                          >
                            </Field>
                        </div>
                      </div>
                      <div className='rounded bg-secondary p-2 mt-4'>
                        <h4 className='text-center'>Permanent Address</h4>
                        <div className='fv-row mb-10 col-12 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Line 1</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='line1'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>City/Town</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='city'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>State</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='state'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>Andhra Pradesh</option>
                            <option value='delhi'>Delhi</option>
                            <option value='voterId'>West Bengal</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>District</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='district'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>North Delhi</option>
                            <option value='delhi'>Central Delhi</option>
                            <option value='voterId'>South Delhi</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Pin Code</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='pincode'
                            placeholder=''
                          >
                          </Field>
                        </div>
                      </div>
                      <div className='rounded bg-secondary p-2 mt-4'>
                        <h4 className='text-center'>Communication Address</h4>
                        <h6 className='text-center text-danger'>FOR ONLINE COURSE STUDENTS BOOKS WILL BE DELIVERED ON THIS ADDRESS ONLY (COMMUNICATION ADDRESS).</h6>
                        <div className='fv-row mb-10 col-12 d-inline-block p-2'>
                          <input
                            type='checkbox'
                            name='usepermanent'
                            placeholder=''
                          >
                          </input>
                          <label className='align-items-center fs-5 fw-bold mb-2'>
                            <span >Communication Address is same as permanent address</span>
                          </label>
                        </div>
                        <div className='fv-row mb-10 col-12 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Line 1</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='line11'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>City/Town</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='city1'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>State</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='state1'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>Andhra Pradesh</option>
                            <option value='delhi'>Delhi</option>
                            <option value='voterId'>West Bengal</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>District</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='district1'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>North Delhi</option>
                            <option value='delhi'>Central Delhi</option>
                            <option value='voterId'>South Delhi</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Pin Code</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='pincode1'
                            placeholder=''
                          >
                          </Field>
                        </div>
                      </div>
                      <div className='rounded bg-secondary p-2 mt-4'>
                        <h4 className='text-center'>Education Details</h4>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Qualification</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='qualification'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>BTech</option>
                            <option value='dl'>BE</option>
                            <option value='voterId'>AMI</option>
                            <option value='voterId'>BA</option>
                            <option value='voterId'>BCom</option>
                            <option value='voterId'>Others</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Stream</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='stream1'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>Civil Engineering</option>
                            <option value='dl'>Others</option>
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Name of the College</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='college'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Passing Year</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='passingyear'
                            placeholder=''
                          ></Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Marks in %</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='marks'
                            placeholder=''
                          ></Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Optional Subject in CSE</span>
                          </label>

                          <Field
                            as='select'
                            className='form-control form-control-lg form-control-solid'
                            name='optional'
                            placeholder=''
                          >
                            <option value=''>--Select--</option>
                            <option value='aadhaar'>Civil Enginering</option>
                            <option value='dl'>Economics</option>
                            <option value='voterId'>Electrical Engineering</option>
                          </Field>
                        </div>
                      </div>
                      <div className='rounded bg-secondary p-2 mt-4'>
                        <h4 className='text-center'>Upload Image/Documents</h4>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Photo</span>
                          </label>
                          <div className='text-center'><img src='https://thumbs.dreamstime.com/b/late-thirties-male-passport-photo-28479105.jpg' style={{height:"200px"}}/></div>
                          <Field
                            type='file'
                            className='form-control form-control-lg form-control-solid mt-3'
                            name='photo'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Signature</span>
                          </label>
                          <div className='text-center'><img src='https://overlay.imageonline.co/image.jpg' style={{height:"200px"}}/></div>
                          <Field
                            type='file'
                            className='form-control form-control-lg form-control-solid mt-3'
                            name='signature'
                            placeholder=''
                          >
                          </Field>
                        </div>
                        <div className='fv-row mb-10 col-6 d-inline-block p-2'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Upload ID Proof</span>
                          </label>
                          <Field
                            type='file'
                            className='form-control form-control-lg form-control-solid mt-3'
                            name='idproof'
                            placeholder=''
                          >
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='me-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>

  )
}

export { Form1 }
