import { FC } from 'react'
import { useLocation } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from '../../../setup/config/firebaseConfig';
import { auth } from "../../../setup/config/firebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import { loaderDiv, removeLoaders } from './loader';
import axios from 'axios';
/*
const takePic = async () => {
  let options: CaptureVideoOptions = { limit: 1, quality : 1, duration: 30 };
  //let options: CaptureVideoOptions = { limit: 1, duration: 30 };
  MediaCapture.captureVideo(options).then((data: any)=>{
    console.log(data);
  })
};
*/
export const TakeVideo: FC = () => {
  var width = 320;    // We will scale the photo width to this
  var height = 0;     // This will be computed based on the input stream

  var streaming = false;
  let recorder: MediaRecorder;
  let isRecording: boolean = false;
  let mainStream: MediaStream;
  let recordedBlob: Blob;
  const location: any = useLocation();
  function uploadPicture() {
    loaderDiv(document.body);
    const storageRef = ref(storage, `/${location.state.folder}/${location.state.filename}`);
    signInWithCustomToken(auth, location.state.token)
      .then((userCredential) => {
        
          const uploadTask = uploadBytesResumable(storageRef, recordedBlob);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              loaderDiv(document.body, progress)
            },
            (err) => { removeLoaders(); console.log(err) },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((fileurl) => {
                let value: any = {};
                value[location.state.name] = fileurl;
                value['type'] = location.state.type;
                axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/' + location.state.applicationId + '/' + location.state.name, value, {//Url
                  headers: {
                    'Content-Type': "application/json"
                  }
                }).then((reply) => {//Errors come in status 200
                  window.location.pathname = location.state.redirectUrl;
                }, (error) => {
                  //reject(error)
                });

              });
            }
          );
        
      }).catch(() => {
        console.log("error")//TODO
      })

  }
  function startup() {
    isRecording = false;
    let recording = document.getElementById('recording') as HTMLVideoElement;
    let video = document.getElementById('video') as HTMLVideoElement;
    let initiateVideo = document.getElementById('initiateVideo') as HTMLButtonElement;
    let takeVideo = document.getElementById('takeVideo') as HTMLButtonElement;
    let finaliseVideo = document.getElementById('finalise') as HTMLButtonElement;
    let stopRecording = document.getElementById('stopRecording') as HTMLButtonElement;
    video.style.display = "block";
    takeVideo.style.display = "inline-block";
    initiateVideo.style.display = "none";
    finaliseVideo.style.display = "none";
    stopRecording.style.display = "none";
    recording.style.display = "none";
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }, audio: true })
      .then(function (stream) {
        mainStream = stream;
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
    video.addEventListener('canplay', function (ev) {
      if (!streaming) {
        height = video.videoHeight / (video.videoWidth / width);
        if (isNaN(height)) {
          height = width / (4 / 3);
        }
        video.setAttribute('width', width.toString());
        video.setAttribute('height', height.toString());
        streaming = true;
      }
    }, false);
  }
  function wait(delayInMS: number) {
    return new Promise((resolve) => setTimeout(resolve, delayInMS));
  }
  function videoTimer(time: number, maxTime: number) {
    if (isRecording) {
      (document.getElementById('timer') as HTMLElement).innerHTML = "Time Elapsed - <b>" + time + "</b> seconds";
      if (time < maxTime) {
        setTimeout(function () { videoTimer(time + 1, maxTime) }, 1000)
      }
    }
  }
  function stopRecording() {
    isRecording = false;
    recorder.stop();
  }
  function startRecording(stream: MediaStream, lengthInMS: number) {
    let recording = document.getElementById('recording') as HTMLVideoElement;
    let initiateVideo = document.getElementById('initiateVideo') as HTMLButtonElement;
    let stopRecording = document.getElementById('stopRecording') as HTMLButtonElement;
    let finaliseVideo = document.getElementById('finalise') as HTMLButtonElement;
    let takeVideo = document.getElementById('takeVideo') as HTMLButtonElement;
    initiateVideo.style.display = 'none';
    stopRecording.style.display = 'inline-block';
    finaliseVideo.style.display = 'none';
    takeVideo.style.display = 'none';
    recording.style.display = "none";
    isRecording = true;
    recorder = new MediaRecorder(stream);
    const data: any = [];

    recorder.ondataavailable = (event) => data.push(event.data);
    recorder.start();
    videoTimer(0, lengthInMS / 1000);
    const stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = (event) => reject();
    });

    const recorded = wait(lengthInMS + 1000).then(
      () => recorder.state == "recording" && recorder.stop()
    );

    return Promise.all([stopped]).then(() => data);
  }
  function takeVideo() {
    let video = document.getElementById('video') as any;
    let recording = document.getElementById('recording') as HTMLVideoElement;
    video.captureStream =
      video.captureStream || video.mozCaptureStream;
    startRecording(video.captureStream(), 30000)
      .then((recordedChunks) => {
        isRecording = false;
        (document.getElementById('timer') as HTMLElement).innerHTML = "";
        recording.style.display = "inline-block";
        recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });
        recording.src = URL.createObjectURL(recordedBlob);
        let initiateVideo = document.getElementById('initiateVideo') as HTMLButtonElement;
        let stopRecording = document.getElementById('stopRecording') as HTMLButtonElement;
        let finaliseVideo = document.getElementById('finalise') as HTMLButtonElement;
        let takeVideo = document.getElementById('takeVideo') as HTMLButtonElement;
        initiateVideo.style.display = 'inline-block';
        stopRecording.style.display = 'none';
        finaliseVideo.style.display = 'inline-block';
        takeVideo.style.display = 'none';
        video.style.display = "none";
        initiateVideo.innerHTML = "Re Record Video";
        mainStream.getTracks().forEach(function (track) {
          track.stop();
        });
      })

  }
  return (
    <div className="contentarea text-center">
      <h1>
        {location.state.header}
      </h1>
      <div className="camera">
        <video id="video" style={{ margin: "auto" }} poster="/images/w3html5.gif" muted>Video stream not available.</video>
        <video id="recording" style={{ margin: "auto", display: "none", maxWidth : "100%" }} controls></video>
      </div>
      <h5 id="timer"></h5>
      <button id='initiateVideo' className='btn btn-primary m-2' onClick={startup} style={{ display: "inline-block" }}>Start Camera</button>
      <button id='finalise' className='btn btn-primary m-2' onClick={uploadPicture} style={{ display: "none" }}>Finalise This Video</button>
      <button id='takeVideo' className='btn btn-primary m-2' onClick={takeVideo} style={{ display: "none" }}>Start Video Recording</button>
      <button id='stopRecording' className='btn btn-primary m-2' onClick={stopRecording} style={{ display: "none" }}>Stop Video Recording</button>
    </div>
  )
}