import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
export const ViewDetails: FC = () => {
  const location: any = useLocation();
  let [stringmap, setStringMap] = useState<any>({});
  let [data, setData] = useState<any>({});
  useEffect(() => {
    console.log(location.state)
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/string-map/'+location.state.companyId, {
      headers: {
        'Content-Type': "application/json"
      }
    }).then((reply) => {//Errors come in status 200
      if(reply?.data?.data?.stringMap) {
        setStringMap(reply.data.data.stringMap)
      }
    }, (error) => {
      //reject(error)//TODO
    });
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/' + location.state.applicationId + '/mixed', {
      headers: {
        'Content-Type': "application/json"
      }
    }).then((reply) => {//Errors come in status 200
      console.log(reply.data.data);
      if(reply?.data?.data) {
        setData(reply.data.data)
      }
    }, (error) => {
      //reject(error)//TODO
    });
  }, [])
  return (
    <div className="text-center m-10">
      <h2>Details</h2>
      <table className="table table-bordered">
      {location.state.displayItems?.map((item : any) => {
        if(item.split('.').length > 1) {
          return (
            <tr key={item}>
              <td className="p-3 col-4" style={{textAlign: "right", border: "1px solid"}}>
                {item.split('.')[0].charAt(0).toUpperCase() + item.split('.')[0].slice(1) + '-'}
                {item.split('.').length > 2 ? stringmap[ item.split('.')[2]] ? stringmap[ item.split('.')[2]].name : item.split('.')[2] : stringmap[ item.split('.')[1]] ? stringmap[ item.split('.')[1]].name : item.split('.')[1]}
              </td>
              <td className="p-3 col-8" style={{border: "1px solid"}}>
                {(!stringmap[ item.split('.')[1]] || stringmap[ item.split('.')[1]].type == 'text') && <>{data[item.split('.').join('-')]}</>}
                {(stringmap[ item.split('.')[1]]?.type == 'image') && <Link to={{
                  pathname: "/fileviewer",
                  state: { 'title': stringmap[ item.split('.')[1]] ? stringmap[ item.split('.')[1]].name : item.split('.')[1], 
                  fileType: 'image', 'file': data[item.split('.').join('-')] }
                }}>View</Link>} 
                {(stringmap[ item.split('.')[1]]?.type == 'pdf') && <Link to={{
                  pathname: "/fileviewer",
                  state: { 'title': stringmap[ item.split('.')[1]] ? stringmap[ item.split('.')[1]].name : item.split('.')[1], 
                  fileType: 'pdf', 'file': data[item.split('.').join('-')] }
                }}>View</Link>} 
              </td>
            </tr>
          );
        } else {
          return (
            <tr key={item}>
              <td className="p-3" style={{textAlign: "right", border: "1px solid"}}>{stringmap[ item ] ? stringmap[ item ].name : item }</td>
              <td className="p-3" style={{border: "1px solid"}}>
                {(!stringmap[ item ] || stringmap[ item ].type == 'text') && <>{data[item]}</>}
                {(stringmap[ item ]?.type == 'image') && <Link to={{
                  pathname: "/fileviewer",
                  state: { 'title': stringmap[ item ] ? stringmap[ item ].name : item , 
                  fileType: 'image', 'file': data[item] }
                }}>View</Link>} 
                {(stringmap[ item ]?.type == 'pdf') && <Link to={{
                  pathname: "/fileviewer",
                  state: { 'title': stringmap[ item ] ? stringmap[ item ].name : item , 
                  fileType: 'pdf', 'file': data[item] }
                }}>View</Link>} 
              </td>
            </tr>
          );
        }
      })}
      </table>
      <button className="btn btn-primary float-end no-print mb-10" onClick={()=>{window.print()}}>Print</button>
    </div>
  )
}