import { FC, useEffect, useRef, useState } from 'react'
import axios, { AxiosResponse } from 'axios';
import { loaderDiv, removeLoaders } from '../../modules/widgets/loader';
import { Modal } from 'react-bootstrap-v5'
import SignatureCanvas from "react-signature-canvas";
import { Capacitor } from '@capacitor/core';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth } from "../../../setup/config/firebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import storage from '../../../setup/config/firebaseConfig';
import { PDFDocument } from 'pdf-lib'
import { CaptureImageOptions, MediaCapture } from '@awesome-cordova-plugins/media-capture';
import { mediaFileToFile } from '../../utilities/mediaFileToFile';

const Sign: FC = () => {
  let sigPad = useRef<SignatureCanvas>(null);
  const [signType, setSignType] = useState("stylus_sign");
  let [fileUrl, setFileUrl] = useState('');
  let [showConfirmModal, setShowConfirmModal] = useState(false);
  let [showSignatureModal, setShowSignatureModal] = useState(false);
  let [file, setFile] = useState<File>(new File([], 'file'));
  let [signData, setSignData] = useState<any>('');
  function blobToDataURL(blob : any, callback : Function) {
    var a = new FileReader();
    a.onload = function (e : any) { callback(e.target.result); }
    a.readAsDataURL(blob);
  }
  const captureMedia = () => {
    let options: CaptureImageOptions = { limit: 1 };
    MediaCapture.captureImage(options).then(async (data: any) => {
      mediaFileToFile(data[0], function (blob: Blob) {
        blobToDataURL(blob, function(dataURL : any) {
          addSignature(dataURL);
        })
      });
    }, (err: any) => {
      //TODO
    })
  }
  const savepad = () => {
    if (!sigPad.current || sigPad.current?.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      var dataURL = sigPad.current.toDataURL();
      addSignature(dataURL);
    }
  }
  const uploadSignature = (e: any) => {
    const file = e.target.files[0];
    let dataURL = URL.createObjectURL(file);
    addSignature(dataURL);
  }
  const addSignature = async (dataURL: string) => {
    console.log("here")
    loaderDiv(document.body);
    const pngImageBytes = await fetch(dataURL).then((res) => res.arrayBuffer())
    const existingPdfBytes = await fetch(fileUrl).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    let pngImage : any;
    try {
      pngImage = await pdfDoc.embedPng(pngImageBytes)
    } catch {
      pngImage = await pdfDoc.embedJpg(pngImageBytes)
    }
    let height = 50;
    let width = 50;
    const pngDims = pngImage.scale(1);
    if (((width) / (height)) > (pngDims.width / pngDims.height)) {
      width = (height / pngDims.height) * pngDims.width;
    } else {
      height = (width / pngDims.width) * pngDims.height;
    }
    Object.keys(signData.signaturePositions).forEach((pageNo: string) => {
      let page = pdfDoc.getPage(parseInt(pageNo) - 1)
      signData.signaturePositions[pageNo].forEach((coords: any) => {
        page.drawImage(pngImage, {
          x: coords.x,
          y: coords.y,
          width: width,
          height: height,
        })
      })
    })
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const file = new File([blob], 'file.pdf');
    signInWithCustomToken(auth, signData.token)
    .then((userCredential) => {
      uploadFile(file, function() {
        axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/signcallbackfile/' + window.location.pathname.split("/")[2] + '/' + window.location.pathname.split("/")[3]).then((reply) => {
          removeLoaders();
          window.location.reload();
        })
      })
    })
    //let formData = new FormData();
    //formData.append('file', file);
  }
  const uploadFile = (file : File, callback: Function) => {
    /*
    if(file instanceof File) {
      console.log("x"+fileType)
      if(!fileName) {fileName = file.name;}
      else {fileName = fileName + (fileType ? fileType : ((file.name && file.name.split) ? ('.' +   file.name.split(".").pop()) : ''))}
    } else {
      console.log("x"+fileType)
      fileName = fileName + (fileType ? fileType : '')
    }
    let metadata : any = {firebaseStorageDownloadTokens: null}
    if (type == 'pdf') {
      metadata['contentType'] = "application/pdf";
    }
    */
    let url = new URL(fileUrl);
    const storageRef = ref(storage, '/' + url.pathname.split('/').slice(2).join('/'));
    const uploadTask = uploadBytesResumable(storageRef, file, {"contentType" : "application/pdf"});
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        loaderDiv(document.body, progress)
      },
      (err) => {removeLoaders();console.log(err)},
      () => {
        callback();
      }
    );
  }
  const onLoadFile = () => {
    let loader = document.getElementById('loader') as HTMLElement;
    loader.style.display = "none";
  }
  const startSign = () => {
    loaderDiv(document.body);
    let pathVars = window.location.pathname.split("/");
    let formData = new FormData();
    formData.append('file', file);
    formData.append('phone', signData.phone);
    formData.append('name', signData.name);
    formData.append('email', signData.email);
    formData.append('callbackUrl', window.location.origin + '/sign-redirect');
    formData.append('signaturePositions', JSON.stringify(signData.signaturePositions));
    axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/sign/' + pathVars[2] + '/' + pathVars[3],
      formData, {
      headers: {
        'Content-Type': "application/x-www-form-urlencoded"
      }
    }).then((data) => {
      removeLoaders();
      if (data?.data?.data?.popupUrl) {
        window.open(data?.data?.data?.popupUrl, '_blank');
      } else {
        //TODO
      }
    }).catch(() => {
      //TODO
    })
  }
  async function getFileFromUrl(url: string) {
    const response = await fetch(url)
    const data = await response.blob()
    setFile(new File([data], 'file'))
    console.log(file);
  }
  useEffect(() => {
    let pathVars = window.location.pathname.split("/");
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/sign/' + pathVars[2] + '/' + pathVars[3]).then((data) => {
      if (data?.data?.data?.url) {
        setFileUrl(data.data.data.url);
        getFileFromUrl(data.data.data.url);
        console.log(data.data.data);
        setSignData(data.data.data);
      }
    }).catch(() => {
      //TODO
    })
  }, [])
  return (<div className="text-center m-4">
    <img id="loader" src="https://complianceeasy.in/assets/images/preloader.gif"></img>
    {fileUrl && fileUrl != '' && <iframe src={fileUrl + '#toolbar=0'} style={{ width: "100%", height: "80vh" }} onLoad={onLoadFile}></iframe>}
    {fileUrl && fileUrl != '' && signData && signData.signed == false && signData.signType == 'aadhaar' && <button className='btn btn-primary' onClick={startSign}>Start Signing</button>}
    {fileUrl && fileUrl != '' && signData && signData.signed == false && signData.signType == 'digital' && <button className='btn btn-primary' onClick={() => { setShowConfirmModal(true) }}>Start Signing</button>}
    <Modal className='bg-white' aria-hidden='true' dialogClassName='modal-fullscreen h-auto' show={showConfirmModal}>
      <div className='modal-content shadow-none'>
        <div className='container-xxl'>
          <div className='modal-header d-flex justify-content-center border-0'>
            <h2>Confirm</h2>
            <div onClick={() => { setShowConfirmModal(false) }} className='btn btn-icon btn-sm btn-light-primary ms-2' style={{ position: "absolute", right: '10px' }}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body'>
            Confirm that you have reviewed the documents and are ready to sign.
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light-primary' onClick={() => { setShowConfirmModal(false) }}>
              I will review
            </button>
            <button id='button' type='button' className='btn btn-primary' onClick={() => { setShowConfirmModal(false); setShowSignatureModal(true) }}>
              I will sign
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal className='bg-white' aria-hidden='true' dialogClassName='modal-fullscreen h-auto' show={showSignatureModal}>
      <div className='modal-content shadow-none'>
        <div className='container-xxl'>
          <div className='modal-header d-flex justify-content-center border-0'>
            <h2>Sign</h2>
            <div onClick={() => { setShowSignatureModal(false) }} className='btn btn-icon btn-sm btn-light-primary ms-2' style={{ position: "absolute", right: '10px' }}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body'>
            <div className=" bg-secondary rounded">
              <div className="card card-custom shadow" style={{ marginTop: "10px", height: "93%" }}>
                <div className="card-header card-header-stretch">
                  <h3 className="card-title">Digital Sign</h3>
                  <div className="card-toolbar">
                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                      <li className="nav-item">
                        <button
                          className={"nav-link" + (signType == "stylus_sign" ? " active" : "")}
                          data-bs-toggle="tab" role="tab"
                          onClick={() => setSignType("stylus_sign")}>
                          Stylus
                        </button>
                      </li>
                      {/*<li className="nav-item">
                        <button
                          className={"nav-link" + (signType == "text_sign" ? " active" : "")}
                          data-bs-toggle="tab" onClick={() => setSignType("text_sign")}>
                          Text
                        </button>
                      </li>*/}
                      <li className="nav-item">
                        <button
                          className={"nav-link" + (signType == "image_sign" ? " active" : "")}
                          data-bs-toggle="tab" onClick={() => setSignType("image_sign")}>
                          Image
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    {signType == "stylus_sign" && (
                      <div className="tab-pane fade show active" id="stylus_sign" role="tabpanel">
                        <div className="col">
                          <div className='d-flex justify-content-center'>
                            <div className="row bg-secondary rounded mb-10" style={{ width: "200px", height: "200px" }}>
                              <SignatureCanvas canvasProps={{ width: 200, height: 200 }} ref={sigPad} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <button className="bg-secondary w-100 text-primary btn btn-primary"
                                onClick={() => { sigPad.current?.clear() }}>
                                Clear
                              </button>
                            </div>
                            <div className="col">
                              <button className="btn btn-primary w-100" onClick={savepad}>
                                Save Sign
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {signType == "text_sign" && (
                      <div className="tab-pane active" id="text_sign" role="tabpanel">
                        {/*<Input placeholder="Enter signer name" type="text" id="signtext" name="signtext"
                          onChange={(e) => { signText(e.target.value); }} value={signtext} />
                        <div className="px-3">
                          <div className="row mt-10">
                            <div className="col bg-secondary rounded mb-1 pr-5 d-flex align-items-center"
                              style={{ height: "70px" }}>
                              <input type="radio" value="sign1text" className="mx-3"
                                checked={signtextx === "sign1text"} onChange={(e) => { setSignTextX(e.target.value) }} />
                              <label ref={sign1text} id="sign1text" htmlFor="sign1text"
                                style={{ fontFamily: "signature1", fontSize: "55px", }}>
                                {signtext}
                              </label>
                            </div>
                            <div className="w-100 mt-3"></div>
                            <div className="col bg-secondary rounded mb-1 d-flex align-items-center ml-5" style={{ height: "70px" }}>
                              <input type="radio" value="sign2text" className="mx-3"
                                checked={signtextx === "sign2text"} onChange={(e) => { setSignTextX(e.target.value) }} />
                              <label ref={sign2text} id="sign2text" htmlFor="sign2text"
                                style={{ fontFamily: "signature2", fontSize: "25px", }}>
                                {signtext}
                              </label>
                            </div>
                            <div className="w-100 mt-3"></div>
                            <div className="col bg-secondary rounded mb-1 d-flex align-items-center" style={{ height: "70px" }}>
                              <input type="radio" value="sign3text" htmlFor="sign3text" className="mx-3"
                                checked={signtextx === "sign3text"} onChange={(e) => { setSignTextX(e.target.value) }} />
                              <label ref={sign3text} id="sign3text" style={{ fontFamily: "cursive", fontSize: "25px", }}>
                                {signtext}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row mt-10 mb-5">
                          <div className="col">
                            <button onClick={() => downloadTextSignImage()} className="w-100">
                              Confirm Signature
                            </button>
                          </div>
                        </div>*/}
                      </div>
                    )}
                    {signType == "image_sign" && (
                      <div className="tab-pane active" id="image_sign" role="tabpanel">
                        {Capacitor.getPlatform() === 'android' &&
                          <button type='button' className='btn btn-secondary' onClick={(e) => {captureMedia() }}>Capture Image</button>
                        }
                        <input type="file" className='btn btn-success' onChange={(e) => { uploadSignature(e) }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*<SignaturePad ref={padRef} canvasProps={{ className: "sigCanvas" }} />*/}
          </div>
        </div>
      </div>
    </Modal>
  </div>)
}
export { Sign }