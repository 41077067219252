import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const FileViewer: FC = () => {
  const location: any = useLocation();
  const [pageNo, setPageNo] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [pdf, setPdf] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const onLoadFile = () => {
    setLoading(false);
  }
  function renderPage(pageNo: number) {
    if (pageNo >= 0 && pageNo < totalPages) {
      onLoadFile();
      pdf.getPage(pageNo + 1).then((page: any) => {
        let pdfViewport = page.getViewport(1);
        const container = document.getElementById('the-canvas') as HTMLCanvasElement;
        pdfViewport = page.getViewport(container.offsetWidth * 5 / pdfViewport.width);
        const canvas = container;
        const context = canvas.getContext('2d');
        canvas.height = pdfViewport.height;
        canvas.width = pdfViewport.width;

        page.render({
          canvasContext: context,
          viewport: pdfViewport,
        });
      });
    }
  }
  useEffect(() => {
    if (location.state.file.indexOf('.pdf') != -1 || location.state.fileType == 'pdf') {
      console.log(location.state.file);
      window.pdfjsLib.getDocument(location.state.file).then((pdf: any) => {
        setTotalPages(pdf.numPages);
        setPdf(pdf);
        setPageNo(0);
      });
    }
  }, [])
  useEffect(() => {
    renderPage(pageNo);
  }, [pageNo])
  return (
    <div className="text-center m-4">
      <h2>{location.state.title}</h2>
      {loading && <img id="loader" src="https://complianceeasy.in/assets/images/preloader.gif"></img>}
      {
        (location.state.file.indexOf('.png') != -1 || location.state.file.indexOf('.PNG') != -1
          || location.state.file.indexOf('.jpg') != -1 || location.state.file.indexOf('.JPG') != -1
          || location.state.file.indexOf('.jpeg') != -1 || location.state.file.indexOf('.JPEG') != -1
          || location.state.fileType == 'image') &&
        <div>
          <div>{location.state.geoLocation && location.state.geoLocation.length == 2 && ('Latitude - ' + location.state.geoLocation[0] + " | Longitude - " + location.state.geoLocation[1])}</div>
          <img src={location.state.file} onLoad={onLoadFile} style={{ maxHeight: "90vh", maxWidth : "100%" }}></img>
        </div>
      }
      {
        (location.state.file.indexOf('.pdf') != -1 
        || location.state.fileType == 'pdf') &&
        <div>
          <div className="col-sm-12 col-md-8 offset-md-2">
            {!loading && <div>
              <button className="btn btn-secondary" onClick={() => { setPageNo(pageNo - 1) }}>Prev</button>
              <span className="m-4 p-4"  >Page {pageNo + 1}</span>
              <button className="btn btn-secondary" onClick={() => { setPageNo(pageNo + 1) }}>Next</button>
            </div>}
            <canvas id="the-canvas" style={{ width: "100%" }}></canvas>
          </div>
        </div>
      }
      {
        (location.state.file.indexOf('.mp4') != -1 || location.state.fileType == 'video') &&
        <video src={location.state.file} onLoadStart={onLoadFile} style={{maxWidth : "100%"}} controls></video>
      }
    </div>
  )
}