import { FC, useState } from 'react'
import { useLocation } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from '../../../setup/config/firebaseConfig';
import { auth } from "../../../setup/config/firebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import { loaderDiv, removeLoaders } from './loader';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture';


export const TakePicture: FC = () => {
  var width = 320;    // We will scale the photo width to this
  var height = 0;     // This will be computed based on the input stream

  var streaming = false;
  let mainStream: MediaStream;
  const location: any = useLocation();
  let [geoLocationCoords, setGeoLocationCoords] = useState<number[]>([]);
  /*
  const takePic = async () => {
    let options: CaptureImageOptions = { limit: 1 };
    //let options: CaptureVideoOptions = { limit: 1, duration: 30 };
    MediaCapture.captureImage(options).then((data: any)=>{
      console.log(data);
    })
  };
  */
  function uploadPicture() {
    loaderDiv(document.body);
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const storageRef = ref(storage, `/${location.state.folder}/${location.state.filename}`);
    signInWithCustomToken(auth, location.state.token)
      .then((userCredential) => {
        canvas.toBlob(function (blob) {
          if (blob == null) {
            throw "error";//TODO
          }
          const uploadTask = uploadBytesResumable(storageRef, blob);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              loaderDiv(document.body, progress)
            },
            (err) => { removeLoaders(); console.log(err) },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((fileurl) => {
                let value: any = {};
                value[location.state.name] = fileurl;
                value['type'] = location.state.type;
                value['geoLocation'] = geoLocationCoords;
                axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/' + location.state.applicationId + '/' + location.state.name, value, {//Url
                  headers: {
                    'Content-Type': "application/json"
                  }
                }).then((reply) => {//Errors come in status 200
                  window.location.pathname = location.state.redirectUrl;
                }, (error) => {
                  //reject(error)
                });

              });
            }
          );
        })
      }).catch(() => {
        console.log("error")//TODO
      })

  }
  function startup() {
    let video = document.getElementById('video') as HTMLVideoElement;
    let initiateVideo = document.getElementById('initiateVideo') as HTMLButtonElement;
    let takePicture = document.getElementById('takePicture') as HTMLButtonElement;
    let finalisePicture = document.getElementById('finalise') as HTMLButtonElement;
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    video.style.display = "block";
    takePicture.style.display = "inline-block";
    canvas.style.display = "none";
    initiateVideo.style.display = "none";
    finalisePicture.style.display = "none";
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }, audio: false })
      .then(function (stream) {
        mainStream = stream;
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
    video.addEventListener('canplay', function (ev) {
      if (!streaming) {
        height = video.videoHeight / (video.videoWidth / width);
        if (isNaN(height)) {
          height = width / (4 / 3);
        }
        video.setAttribute('width', width.toString());
        video.setAttribute('height', height.toString());
        canvas.setAttribute('width', width.toString());
        canvas.setAttribute('height', height.toString());
        streaming = true;
      }
    }, false);
    clearphoto();
  }

  function clearphoto() {
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var context = canvas.getContext('2d');
    if (context) {
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
  }

  function takepicture() {
    loaderDiv(document.body);
    navigator.geolocation.getCurrentPosition(function (geolocation : GeolocationPosition) {
      console.log(geolocation);
      setGeoLocationCoords([geolocation.coords.latitude, geolocation.coords.longitude]);
      let video = document.getElementById('video') as HTMLVideoElement;
      let canvas = document.getElementById('canvas') as HTMLCanvasElement;
      let initiateVideo = document.getElementById('initiateVideo') as HTMLButtonElement;
      let takePicture = document.getElementById('takePicture') as HTMLButtonElement;
      let finalisePicture = document.getElementById('finalise') as HTMLButtonElement;

      canvas.style.display = "block";
      takePicture.style.display = "none";
      initiateVideo.style.display = "inline-block";
      finalisePicture.style.display = "inline-block";
      initiateVideo.innerHTML = "Retake Picture";

      var context = canvas.getContext('2d');
      removeLoaders();
      if (width && height) {
        canvas.width = width;
        canvas.height = height;
        if (context) {
          context.drawImage(video, 0, 0, width, height);
        }
        video.style.display = "none";
        mainStream.getTracks().forEach(function (track) {
          track.stop();
        });
      } else {
        clearphoto();
      }
    }, function () {
      removeLoaders();
      console.log("no location")//TODO
    });
  }
  return (
    <div className="contentarea text-center">
      <h1>
        {location.state.header}
      </h1>
      <div className="camera">
        <video id="video" style={{ margin: "auto" }} poster="/images/w3html5.gif" autoPlay>Video stream not available.</video>
        <canvas id="canvas" style={{ margin: "auto", maxWidth : "100%" }}></canvas>
        {geoLocationCoords && geoLocationCoords.length == 2 && ('Latitude - ' + geoLocationCoords[0] + " | Longitude - " + geoLocationCoords[0])}
      </div>
      <button id='initiateVideo' className='btn btn-primary m-2' onClick={startup} style={{ display: "inline-block" }}>Start Camera</button>
      <button id='finalise' className='btn btn-primary m-2' onClick={uploadPicture} style={{ display: "none" }}>Finalise This Picture</button>
      <button id='takePicture' className='btn btn-primary m-2' onClick={takepicture} style={{ display: "none" }}>Take Picture</button>
    </div>
  )
}