/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
export function AsideMenuCustom(menuItems: any) {
  const intl = useIntl()

  return (
    <>
    {/*<AsideMenuItemWithSub
        to='/crafted/pages'
        title='Applications'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm010.svg'
  >*/}
      {menuItems.menuItems.map((item: any) => {
        return (<AsideMenuItem key={item[Object.keys(item)[0]].toString()} to={item[Object.keys(item)[0]]} title={Object.keys(item)[0]} hasBullet={false} />)
      })
      }
      {/*</AsideMenuItemWithSub>*/}
    </>
  )
}
