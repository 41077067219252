import { sampleJSON } from './SampleDashboard';

export class Dashboard {
  logo: string;
  statusMap: any;
  asideBarStatusList: number[];
  documentLinkList: number[];
  newApplication: boolean;
  constructor(inputJSON?: any) {
    if(!inputJSON) {
      inputJSON = sampleJSON;
    }
    if (inputJSON.statusMap && inputJSON.asideBarStatusList && inputJSON.documentLinkList && inputJSON.newApplication !== undefined) {
      this.statusMap = inputJSON.statusMap;
      this.logo = inputJSON.logo;
      this.asideBarStatusList = inputJSON.asideBarStatusList;
      this.documentLinkList = inputJSON.documentLinkList;
      this.newApplication = inputJSON.newApplication;
    } else {
      throw 'error';//TODO
    }
  }
}