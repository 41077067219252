import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../setup/config/firebaseConfig";
import { auth } from "../../setup/config/firebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import { loaderDiv, removeLoaders } from "../modules/widgets/loader";
import { resizeImage } from "./resizeImage";
import makeId from "./makeId";
const upload = (file: File | Blob, callback: Function, folder?: string, type?: string, fileName? : string, fileType? : string) => {
  if(file instanceof File) {
    console.log("x"+fileType)
    if(!fileName) {fileName = file.name;}
    else {fileName = fileName + (fileType ? fileType : ((file.name && file.name.split) ? ('.' +   file.name.split(".").pop()) : ''))}
  } else {
    console.log("x"+fileType)
    fileName = fileName + (fileType ? fileType : '')
  }
  const storageRef = ref(storage, `/${folder}/${fileName}`);
  let metadata : any = {firebaseStorageDownloadTokens: null}
  if (type == 'pdf') {
    metadata['contentType'] = "application/pdf";
  }
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      loaderDiv(document.body, progress)
    },
    (err) => {removeLoaders();console.log(err)},
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((fileurl) => {
        removeLoaders();
        callback(fileurl);//TODO - secure this?
      });
    }
  );
}
export function fileUpload (file: File | Blob, callback: Function, folder?: string, token?: string, type?: string, fileName? : string, fileType? : string) {
  if (!folder || folder == '') folder = 'files'
  loaderDiv(document.body);
  if(token) {
    signInWithCustomToken(auth, token)
    .then((userCredential) => {
      upload(file, callback, folder, type, fileName, fileType);
    })
  } else {
    upload(file, callback, folder, type, fileName,fileType);
  }
}