import axios, { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react'
import { Page, Input, HTML } from '../../modules/models/Page';
import { Formik, Form, FormikErrors, FormikTouched, Field, FormikValues } from 'formik';
import { fileUpload } from '../../utilities/fileUpload';
import { mediaCapture } from '../../utilities/mediaCapture';
import { Link, useParams } from 'react-router-dom';
import { loaderDiv, removeLoaders } from '../../modules/widgets/loader';
import { resizeImage } from '../../utilities/resizeImage';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { stateList } from '../../utilities/stateList';
import { write } from 'fs';

function downloadURI(uri: string) {
  var link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const writeToClipboard = async (text : string) => {
  if(Capacitor.getPlatform() !== 'android') {
    navigator.clipboard.writeText(text)
  } else {
    await Clipboard.write({
      string: text
    });
  }
};
const captureMedia = (type: string, samplePage: Page, sectionIndex: number, updatePage: Function, component: Input, values: FormikValues, needGeoLocation?: boolean, setErrors?: Function) => {
  mediaCapture(type, function (blob: Blob, geoLocation?: number[], fileType?: string) {
    processUpload(samplePage, sectionIndex, updatePage, blob, component, values, geoLocation, fileType, setErrors);
  }, needGeoLocation)
}
const onChange = (samplePage: Page, sectionIndex: number, updatePage: Function, component: Input, values: FormikValues, setErrors?: Function) => {
  let input: any = document.getElementById(component.name);
  let originalFile = input.files[0];
  processUpload(samplePage, sectionIndex, updatePage, originalFile, component, values, undefined, undefined, setErrors);
}
const editStamp = (add: boolean, samplePage: Page, sectionIndex : number, updatePage: Function, component: Input, values: FormikValues, setErrors?: Function) => {
  axios.post(process.env.REACT_APP_FINANCE_API_URL + samplePage.sections[sectionIndex].submitData.url, values, {
    headers: {
      'Content-Type': "application/json"
    }
  }).then((reply) => {
    if (add) {
      values.applicationId = window.location.pathname.split('/')[2];
      axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/get-for-amount', values, {
        headers: {
          'Content-Type': "application/json"
        }
      }).then((reply) => {
        console.log(reply)
        if (reply.data?.data?.hasError) {
          removeLoaders();
          if (setErrors) setErrors({ [component.name]: reply.data.data.error });
        } else {
          updatePage();
        }
      })
    } else {
      values.applicationId = window.location.pathname.split('/')[2];
      axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/remove', values, {
        headers: {
          'Content-Type': "application/json"
        }
      }).then((reply) => {
        if (reply.data?.data?.hasError) {
  
        } else {
          updatePage();
        }
      })
    }
  })
}
const processUpload = (samplePage: Page, sectionIndex: number, updatePage: Function, originalFile: File | Blob, component: Input, values: FormikValues, geoLocation?: number[], fileType?: string, setErrors?: Function) => {
  console.log("here" + fileType);
  values.page = window.location.pathname.split('/')[1];
  if (geoLocation) {
    values['geoLocation'] = geoLocation;
  }
  axios.post(process.env.REACT_APP_FINANCE_API_URL + samplePage.sections[sectionIndex].submitData.url, values, {
    headers: {
      'Content-Type': "application/json"
    }
  }).then((reply) => {//Errors come in status 200
    let applicationId = window.location.pathname.split('/')[window.location.pathname.split("/").length - 2];

    let value: any = {};
    resizeImage(originalFile, 1000).then((file) => {
      fileUpload(file, function (fileurl: string) {
        value[component.name] = fileurl;
        value['type'] = samplePage.sections[sectionIndex].initValues.type;
        console.log("here")
        if (component.name == 'aadhaarDocumentFront' || component.name == 'panDocument' || component.name == 'aadhaarDocumentBack') {
          let ocrType = component.name;
          loaderDiv(document.body);
          let formData = new FormData();
          formData.append('file', file);
          formData.append('type', samplePage.sections[sectionIndex].initValues.type)
          axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/ocr/' + (ocrType) + '/' + applicationId, formData, {//Url
            headers: {
              'Content-Type': "application/x-www-form-urlencoded"
            }
          }).then((reply) => {//Errors come in status 200
            console.log(reply);
            if(reply?.data?.data && !reply.data.data.hasError) {
              axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/' + applicationId + '/' + component.name, value, {//Url
                headers: {
                  'Content-Type': "application/json"
                }
              }).then((reply) => {
                //Errors come in status 200
                updatePage();
              }, (error) => {
                removeLoaders();
                //reject(error)
              });
            } else {
              removeLoaders();
              if (setErrors) setErrors({ [component.name]: reply.data.data.error });
            }
          }, (error) => {
            removeLoaders();
            //reject(error)
          });
        } else {
          axios.post(process.env.REACT_APP_FINANCE_API_URL + 'finance/application/' + applicationId + '/' + component.name, value, {//Url
            headers: {
              'Content-Type': "application/json"
            }
          }).then((reply) => {
            //Errors come in status 200
            updatePage();
          }, (error) => {
            removeLoaders();
            //reject(error)
          });
        }

      }, 'compliance-easy/' + samplePage.sections[sectionIndex].initValues.companyId + '/' + samplePage.sections[sectionIndex].initValues.accessLevel + '/' + samplePage.sections[sectionIndex].initValues.id, samplePage.sections[sectionIndex].initValues.token, undefined, samplePage.sections[sectionIndex].initValues.type + '-' + component.name, fileType)
    })
  })

}
export const Display = (samplePage: Page, sectionIndex: number, updatePage: Function, setModalValues: Function, addRemark: Function, component: Input | HTML, values: FormikValues, disabled: boolean, errors?: FormikErrors<any>, touched?: FormikTouched<any>, setFieldValue?: Function, setErrors?: Function) => {
  if (component instanceof Input) {
    return (
      <div className='fv-row mb-20' key={component.name}>
        {component.type == 'text' && <><label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={component.required ? 'required' : ''}>{component.label}</span>
        </label>
          <Field
            disabled={disabled}
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={component.name}
            placeholder={component.placeholder}
          /></>}
        {component.type == 'check' && <><label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={component.required ? 'required' : ''}>{component.label}</span>
        </label>
          <Field
            disabled={disabled}
            type='checkbox'
            className='form-check-input' 
            name={component.name}
          /></>}
        {component.type == 'remarks' && <><label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={component.required ? 'required' : ''}>{component.label}</span>
        </label>
          {samplePage.sections[sectionIndex].initValues.remarks && samplePage.sections[sectionIndex].initValues.remarks.length > 0 &&
            <div >
              {samplePage.sections[sectionIndex].initValues.remarks.map((remark: any) => {
                return (
                  <div className='p-2 bg-secondary mb-1'>
                    <div>
                      {remark.text}
                    </div>
                    <div style={{ fontSize: "10px", textAlign: 'right' }}><b>{remark.name}</b></div>
                  </div>
                )
              })}
            </div>
          }
          <Field
            disabled={disabled}
            as='textarea'
            className='form-control form-control-lg form-control-solid'
            name={'text' + component.name}
            id={'text' + component.name}
            placeholder={component.placeholder}
          />
          <button disabled={disabled} type="button" className='float-end btn btn-primary' onClick={() => addRemark(component, values, setFieldValue)}>Add</button>
        </>
        }
        {component.type == 'date' && <><label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={component.required ? 'required' : ''}>{component.label}</span>
        </label>
          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name={component.name}
            disabled={disabled}
          /></>}
        {component.type == 'viewdetails' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='col-6'>{component.label}</span>
            <Link className='btn btn-secondary col-6' to={{
              pathname: "/viewdetails",
              state: { 'applicationId': window.location.pathname.split('/')[window.location.pathname.split("/").length - 2], 'companyId': samplePage.sections[sectionIndex].initValues.companyId, displayItems: component.displayItems }
            }}>View Details</Link>
          </label>
        </>
        }
        {(component.type == 'file' || component.type == 'viewfile') && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={(component.required ? 'required' : '') + ' col-6'}>{component.label}</span>
            {samplePage.sections[sectionIndex].initValues[component.name] && samplePage.sections[sectionIndex].initValues[component.name] != ''
              && <Link className='btn btn-secondary col-6' to={{
                pathname: "/fileviewer",
                state: { 'title': component.label, fileType: 'pdf', 'file': samplePage.sections[sectionIndex].initValues[component.name] }
              }}>View</Link>}
          </label>
          {(component.type != 'viewfile' && !disabled) && <label className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'>
            <input type='file' id={component.name} name={component.name} onChange={(e) => { onChange(samplePage, sectionIndex, updatePage, component, values, setErrors) }}
              accept="application/pdf"></input>
          </label>}
        </>
        }
        {component.type == 'camera-image' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={(component.required ? 'required' : '') + ' col-6'}>{component.label}</span>
            {samplePage.sections[sectionIndex].initValues[component.name] && samplePage.sections[sectionIndex].initValues[component.name] != ''
              && <Link className='btn btn-secondary col-6' to={{
                pathname: "/fileviewer",
                state: { 'title': component.label, fileType: 'image', 'file': samplePage.sections[sectionIndex].initValues[component.name] }
              }}>View</Link>}
          </label>
          {!disabled && Capacitor.getPlatform() !== 'android' && <label className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'>
            <input type='file' id={component.name} name={component.name} onChange={(e) => { onChange(samplePage, sectionIndex, updatePage, component, values, setErrors) }}></input>
          </label>}
          {!disabled && Capacitor.getPlatform() === 'android' && <label className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'>
            <button type='button' className='btn btn-secondary' onClick={(e) => captureMedia('image', samplePage, sectionIndex, updatePage, component, values, undefined, setErrors)}>Upload Image</button>
          </label>}
        </>
        }
        {component.type == 'stamp' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={component.required ? 'required' : ''}>Stamp Paper</span>
          </label>
          {(!samplePage.sections[sectionIndex].initValues['stamps'] || samplePage.sections[sectionIndex].initValues['stamps'].length == 0) && <>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={component.required ? 'required' : ''}>State</span>
            </label>
            <Field as="select" className='form-control form-control-lg form-control-solid'
              name={'stampState'} placeholder={"State"} >
              {stateList.map((state: string) => {
                return <option value={state}>{state}</option>
              })}
            </Field>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={component.required ? 'required' : ''}>Amount</span>
            </label>
            <Field type='text' className='form-control form-control-lg form-control-solid'
              name={'stampAmount'} placeholder={"Amount"} />
            <button disabled={disabled} type="button" className='float-end btn btn-primary' onClick={() => editStamp(true, samplePage, sectionIndex, updatePage, component, values, setErrors)}>Add</button>
          </>}
          {samplePage.sections[sectionIndex].initValues['stamps'] && samplePage.sections[sectionIndex].initValues['stamps'].length > 0 && <>
            {samplePage.sections[sectionIndex].initValues['stamps'].map((stamp: any) => {
              return (<div>
                {stamp.state} - {stamp.denomination}
              </div>)
            })}

            <button disabled={disabled} type="button" className='float-end btn btn-danger' onClick={() => editStamp(false, samplePage, sectionIndex, updatePage, component, values, setErrors)}>Remove</button>
          </>}
        </>}
        {component.type == 'agreement' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={(component.required ? 'required' : '') + ' col-6'}>{component.label}</span>
            {samplePage.sections[sectionIndex].initValues[component.name] && samplePage.sections[sectionIndex].initValues[component.name] != ''
              &&
              <>
                <button type='button' className='btn btn-secondary col-6' onClick={() => downloadURI(samplePage.sections[sectionIndex].initValues[component.name])}>Download</button>
              </>
            }
          </label>
          {samplePage.sections[sectionIndex].initValues['customers'].map((customer: any) => {
            return (<button type='button' onClick={() => {
              setModalValues({
                show: true, title: [customer.type].map((word) => word.charAt(0).toUpperCase() + word.slice(1))[0] + ' Agreement Link',
                body: <div className='text-center'>
                  <div className="shadow" onClick={() => {writeToClipboard(window.location.origin + '/sign-document/' + window.location.pathname.split('/')[2] + '/' + customer.id)}} style={{ fontSize: "14px" }}>{window.location.origin + '/sign-document/' + window.location.pathname.split('/')[2] + '/' + customer.id}</div>
                </div>
              })
            }} className={'m-2 btn btn-' + (customer.hasSigned ? 'success' : 'danger')}>{customer.type}</button>)
          })}
        </>
        }
        {component.type == 'takepicture' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={(component.required ? 'required' : '') + ' col-6'} >{component.label}</span>
            {samplePage.sections[sectionIndex].initValues[component.name] && samplePage.sections[sectionIndex].initValues[component.name] != ''
              && <Link className='btn btn-secondary col-6' to={{
                pathname: "/fileviewer",
                state: { 'title': component.label, fileType: 'image', 'file': samplePage.sections[sectionIndex].initValues[component.name], geoLocation: samplePage.sections[sectionIndex].initValues['geoLocation'] }
              }}>View</Link>}
          </label>
          {!disabled && <label className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'>
            {Capacitor.getPlatform() !== 'android' && <Link className='btn btn-secondary col-12' to={{
              pathname: "/takepicture",
              state: { 'applicationId': window.location.pathname.split('/')[window.location.pathname.split("/").length - 2], 'name': component.name, 'redirectUrl': window.location.pathname, 'type': samplePage.sections[sectionIndex].initValues.type, 'header': component.label, 'token': samplePage.sections[sectionIndex].initValues.token, 'filename': samplePage.sections[sectionIndex].initValues.type + '-' + component.name + '.png', 'folder': 'compliance-easy/' + samplePage.sections[sectionIndex].initValues.companyId + '/' + samplePage.sections[sectionIndex].initValues.accessLevel + '/' + samplePage.sections[sectionIndex].initValues.id }
            }}>Take Picture</Link>}
            {Capacitor.getPlatform() === 'android' &&
              <button type='button' className='btn btn-secondary' onClick={(e) => captureMedia('image', samplePage, sectionIndex, updatePage, component, values, true, setErrors)}>Capture Image</button>
            }
          </label>}
        </>
        }
        {component.type == 'takevideo' && <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className={(component.required ? 'required' : '') + ' col-6'} >{component.label}</span>
            {samplePage.sections[sectionIndex].initValues[component.name] && samplePage.sections[sectionIndex].initValues[component.name] != ''
              && <Link className='btn btn-secondary col-6' to={{
                pathname: "/fileviewer",
                state: { 'title': component.label, fileType: 'video', 'file': samplePage.sections[sectionIndex].initValues[component.name] }
              }}>View</Link>}
          </label>
          {!disabled && <label className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'>
            {Capacitor.getPlatform() !== 'android' && <Link className='btn btn-secondary col-12' to={{
              pathname: "/capturevideo",
              state: { 'applicationId': window.location.pathname.split('/')[window.location.pathname.split("/").length - 2], 'name': component.name, 'redirectUrl': window.location.pathname, 'type': samplePage.sections[sectionIndex].initValues.type, 'header': component.label, 'token': samplePage.sections[sectionIndex].initValues.token, 'filename': samplePage.sections[sectionIndex].initValues.type + '-' + component.name + '.mp4', 'folder': 'compliance-easy/' + samplePage.sections[sectionIndex].initValues.companyId + '/' + samplePage.sections[sectionIndex].initValues.accessLevel + '/' + samplePage.sections[sectionIndex].initValues.id }
            }}>Capture Video</Link>}
            {Capacitor.getPlatform() === 'android' &&
              <button type='button' className='btn btn-secondary' onClick={(e) => captureMedia('video', samplePage, sectionIndex, updatePage, component, values, undefined, setErrors)}>Capture Video</button>}
          </label>}
        </>
        }
        {errors && errors[component.name] && <div className='text-danger'>{errors[component.name]}</div>}
      </div>)
  } else if (component instanceof HTML) {
    return (<div dangerouslySetInnerHTML={{ __html: component.html }} />)
  } else {
    return (<></>)
  }
}