import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
 
// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
    authDomain: "olearno-4b1fb.firebaseapp.com",
    databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "olearno-4b1fb",
    storageBucket: "olearno-4b1fb.appspot.com",
    messagingSenderId: "990749151543",
    appId: "1:990749151543:web:0536fd45416ff2c52acab2",
    measurementId: "G-SLR9MLDJCL"
};
  
 
// Firebase storage reference
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth =  getAuth(app);
export default storage;