import React from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../helpers'

const SimpleLayout: React.FC = ({children}) => {
  return (
    <PageDataProvider>
      <div className='py-lg-10 px-lg-10 no-print' style={{ background: 'white', padding: "10px", }}>
      <Link to='/dashboard' style={{ position : 'fixed', top : "0", background: "white", height: "65px", width:"500px"}}>
            <img
              alt='Logo'
              className='h-35px logo m-5'
              src={toAbsoluteUrl('/media/logos/logo_1.png')}
            />
          </Link>
      <HeaderWrapper />
      </div>
      <div className='d-lg-none' style={{height : "50px"}}></div>
      <div>
        {children}
      </div>
      <MasterInit />
      
    </PageDataProvider>
  )
}

export {SimpleLayout}
