export function loaderDiv(loaderParent: HTMLElement, percentLoaded?: number) {
  var tagParent = document.createElement("div");
  tagParent.style.cssText = 'width:100%;height:100%;position:fixed;z-index:100000;background-color:white';
  tagParent.setAttribute("name", "loader");
  var tag = document.createElement("div");
  tag.style.cssText = 'width:100%;height:100%;position:fixed;z-index:1000';//position:absolute;top:300px;left:300px;width:200px;height:200px;-moz-border-radius:100px;border:1px  solid #ddd;-moz-box-shadow: 0px 0px 8px  #fff;display:none;';
  tag.style.backgroundImage = 'url("https://complianceeasy.in/assets/images/preloader.gif")';
  tag.style.backgroundRepeat = "no-repeat";
  tag.style.backgroundPosition = "center";
  if (percentLoaded) {
    var loadingPercent = document.createElement("div");
    loadingPercent.className = 'text-center';
    loadingPercent.style.cssText = 'position: fixed; z-index: 1000; bottom: 20%;width:100%;font-size:20px';
    loadingPercent.innerHTML = "... Loading " + percentLoaded + "%";
    tag.appendChild(loadingPercent);
  }
  tagParent.appendChild(tag);
  removeLoaders();
  loaderParent.appendChild(tagParent);
}
const removeLoaders = () => {
  let loaders = document.getElementsByName('loader');
  while(loaders.length > 0){
    if(loaders != null && loaders[0] !== null &&loaders[0].parentNode != null) {
      loaders[0].parentNode.removeChild(loaders[0]);
    }
  }
}
export {removeLoaders};