import { Modal } from "react-bootstrap-v5"
function closeModal(modalValues: any, setModalValues: Function) {
  let updatedModalValues = { ...modalValues };
  updatedModalValues.show = false;
  setModalValues(updatedModalValues)
}
export function DocumentModal(modalValues: any, setModalValues: Function) {
  return (
    <Modal className='bg-white' aria-hidden='true' dialogClassName='modal-fullscreen h-auto' show={modalValues.show}>
      <div className='modal-content shadow-none'>
        <div className='container-xxl'>
          <div className='modal-header d-flex justify-content-center border-0'>
            <h2>{modalValues.title}</h2>
            <div onClick={() => { closeModal(modalValues, setModalValues) }} className='btn btn-icon btn-sm btn-light-primary ms-2' style={{ position: "absolute", right: '10px' }}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body'>
            {modalValues.body}
          </div>
        </div>
      </div>
    </Modal>
  )
}