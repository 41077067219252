import { FC, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios';
import { fileUpload } from '../../utilities/fileUpload';
import makeId from '../../utilities/makeId';
import { useParams } from "react-router-dom";
import { Formik, Field, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import { stateList } from '../../utilities/stateList';

const StampPaper: FC = () => {
  type PageParams = {
    pageType: string;
  };
  const { pageType } = useParams<PageParams>()
  let [init, setInit] = useState<any>({});
  const addSchema = Yup.object().shape({
    companyId: Yup.string().required(),
    stampPaper: Yup.mixed().required(),
    stampPaperId: Yup.string().required(),
    state: Yup.string().required(),
    denomination: Yup.string().required(),
  })
  const listSchema = Yup.object().shape({
    companyId: Yup.string().required(),
  })
  const onSubmit = (values: FormikValues) => {
    if (pageType == 'add') {
      fileUpload(values.stampPaper, function (fileurl: string) {
        values.fileLocation = fileurl;
        axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/edit/', values).then((data) => {
        })
      }, 'compliance-easy/' + values.companyId + '/2/stampPapers/', init.token, undefined, values.stampPaperId + makeId(5), '.pdf')
    }
    if (pageType == 'list') {

      axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/list/', values).then((data) => {
        setInit(data.data.data)
        console.log(data)
      })
    }
    if (pageType == 'summary') {

      axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/summary/', values).then((data) => {
        setInit(data.data.data)
        console.log(data)
      })
    }
    console.log(values);
  }
  useEffect(() => {
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/init').then((data) => {
      setInit(data.data);
      if(!data.data.admin && pageType == 'summary') {
        axios.post(process.env.REACT_APP_FINANCE_API_URL + 'stamp-paper/summary/').then((data) => {
          setInit(data.data.data)
          console.log(data)
        })
      }
    })
  }, [pageType])
  return (<div className="text-center m-10">
    {pageType == 'links' &&
      <div>
        <a href='/stamp-paper/add'>Add</a><br />
        <a href='/stamp-paper/list'>List</a><br />
        <a href='/stamp-paper/summary'>Summary</a><br />
      </div>
    }
    {pageType == 'add' &&
      <Formik
        enableReinitialize={true} validationSchema={addSchema}
        initialValues={{}} validateOnChange={false} validateOnBlur={false} onSubmit={onSubmit}>
        {({ errors, touched, values, setFieldValue }) =>
          <Form className='form row text-center' noValidate id='kt_modal_create_app_form'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2 col-6 d-inline-block'>
              <span className='required'>Company Id</span>
            </label>
            <Field type='text' className='form-control form-control-lg form-control-solid col-6 d-inline-block'
              name={'companyId'} placeholder={"Company Id"} />
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Stamp Paper</span>
            </label>
            <input id="file" name="file" type="file" onChange={(event) => {
              setFieldValue("stampPaper", event.currentTarget.files ? event.currentTarget.files[0] : '');
            }} />
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>State</span>
            </label>
            <Field as="select" className='form-control form-control-lg form-control-solid'
              name={'state'} placeholder={"State"} >
                {stateList.map((state : string)=> {
                  return <option value={state}>{state}</option>
                })}
                </Field>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Denomination</span>
            </label>
            <Field type='text' className='form-control form-control-lg form-control-solid'
              name={'denomination'} placeholder={"Denomination"} />
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Stamp Paper Id</span>
            </label>
            <Field type='text' className='form-control form-control-lg form-control-solid'
              name={'stampPaperId'} placeholder={"Stamp Paper Id"} />
            <button className='btn btn-primary'>Save</button>
          </Form>}
      </Formik>
    }
    {(pageType == 'list') &&
      <div>
        <Formik
          enableReinitialize={true} validationSchema={listSchema}
          initialValues={{}} validateOnChange={false} validateOnBlur={false} onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue }) =>
            <Form className='form row text-center' noValidate id='kt_modal_create_app_form'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2 col-6 d-inline-block'>
                <span className='required'>Company Id</span>
              </label>
              <Field type='text' className='form-control form-control-lg form-control-solid col-6 d-inline-block'
                name={'companyId'} placeholder={"Company Id"} />
              <button className='btn btn-primary'>Save</button>
            </Form>
          }</Formik>
        <table className='table table-bordered'>
          <thead>
            <tr><th>Stamp Paper Id</th><th>Denomination</th><th>State</th><th>Link</th></tr>
          </thead>
          <tbody>
            {
              init?.list?.map((l: any) => {
                return (
                  <tr><td>{l.stampPaperId}</td><td>{l.denomination}</td><td>{l.state}</td>
                    <td><a href={l.fileLocation}>Link</a></td></tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    }
    {(pageType == 'summary') &&
      <div>
        {init?.admin && <Formik
          enableReinitialize={true} validationSchema={listSchema}
          initialValues={{}} validateOnChange={false} validateOnBlur={false} onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue }) =>
            <Form className='form row text-center' noValidate id='kt_modal_create_app_form'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2 col-6 d-inline-block'>
                <span className='required'>Company Id</span>
              </label>
              <Field type='text' className='form-control form-control-lg form-control-solid col-6 d-inline-block'
                name={'companyId'} placeholder={"Company Id"} />
              <button className='btn btn-primary'>Save</button>
            </Form>
          }</Formik>}
        <table className='table table-bordered'>
          <thead>
            <tr><th>State</th><th>Denomination</th><th>Active</th><th>Used</th></tr>
          </thead>
          <tbody>
            {
              init?.list?.map((l: any) => {
                return (
                  <tr><td>{l._id.state}</td><td>{l._id.denomination}</td><td>{l.activeCount}</td>
                    <td>{l.usedCount}</td></tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    }
  </div>)
}
export { StampPaper }