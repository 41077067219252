/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { SimpleLayout } from '../../_metronic/layout/SimpleLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { Form1 } from '../pages/forms/Form1'
import { Page1 } from '../pages/page/Page'
import { Dashboard1 } from '../pages/page/Dashboard'
import { Sign } from '../pages/page/Sign'
import { SignCallback } from '../pages/page/SignCallback'
import { TakePicture } from '../modules/widgets/TakePicture'
import { TakeVideo } from '../modules/widgets/TakeVideo'
import { FileViewer } from '../modules/widgets/FileViewer'
import { ApiPage } from '../modules/apis/ApiPage'
import { StampPaper } from '../pages/page/StampPaper'
import { ViewDetails } from '../modules/widgets/ViewDetails'


const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

  return (
    <Switch>

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Switch>
          <Route path='/sign-document/:applicationId/:customerId' component={Sign} />
          <Route path='/sign-redirect/:clientId?' component={SignCallback} />

          <Route>
            <AuthPage />
          </Route>
        </Switch>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/dashboard' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <Switch>
          <SimpleLayout>
            <Route path='/takepicture' component={TakePicture} />
            <Route path='/capturevideo' component={TakeVideo} />
            <Route path='/fileviewer' component={FileViewer} />
            <Route path='/viewdetails' component={ViewDetails} />
            <Route path='/sample/form1' component={Form1} />
            <Route exact path='/' component={Dashboard1} />
            <Route path='/dashboard/:status?' component={Dashboard1} />
            <Route path='/field-visit/:applicationId/:section?' component={Page1} />
            <Route path='/loan-application/:applicationId/:section?' component={Page1} />
            <Route path='/loan-disbursement/:applicationId/:section?' component={Page1} />
            <Route path='/signature/:applicationId/:section?' component={Page1} />
            <Route path='/reopen-application/:applicationId/:section?' component={Page1} />
            <Route path='/sign-document/:applicationId/:customerId' component={Sign} />
            <Route path='/sign-redirect/:clientId?' component={SignCallback} />
            <Route path='/stamp-paper/:pageType' component={StampPaper} />
            <Route path='/sandbox/:api/:type' component={ApiPage} />
          </SimpleLayout>
        </Switch>
      )}
    </Switch>
  )
}

export { Routes }
