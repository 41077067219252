let sampleJSON = {
  'id' : 'sample-page',
  logo : {
    html: '<img src=\'https://complianceeasy.in/assets/images/logo_1.png\' style=\'height:30px;margin-bottom:20px\'/>',
    name: 'title',
  },
  sections : [
    {
      inputs : [
        {
          type: 'text',
          label: 'a',
          name: 'a',
          required: true
        },
      ],
      submitData: {
        url: ['',],
      },
      getData: {
        url: ['',],
      },
      progress: {
        title: '1',
        description: 'a',
      }
    },
  ]
}/*{
  'id' : 'sample-page',
  logo : {
    html: '<img src=\'https://complianceeasy.in/assets/images/logo_1.png\' style=\'height:30px;margin-bottom:20px\'/>',
    name: 'title',
  },
  sections : [
    {
      inputs : [
        {
          type: 'text',
          label: 'Aadhaar Number',
          name: 'aadhaarNo',
          required: true
        },
        {
          type: 'file',
          label: 'Aadhaar Document',
          name: 'aadhaarDocument',
          required: true
        },
        {
          type: 'text',
          label: 'PAN Number',
          name: 'panNo',
          required: true
        },
        {
          type: 'file',
          label: 'PAN Document',
          name: 'panDocument',
          required: true
        },
        {
          type: 'text',
          label: 'First Name',
          name: 'firstName',
          required: true
        },
        {
          type: 'text',
          label: 'Last Name',
          name: 'lastName',
          required: true
        },
        {
          type: 'date',
          label: 'Date of Birth',
          name: 'dob',
          required: true
        },
        {
          type: 'text',
          label: 'Father Name',
          name: 'fatherName',
          required: true
        },
        {
          type: 'text',
          label: 'Loan Amount',
          name: 'loanAmount',
          required: true
        },
        {
          type: 'text',
          label: 'Tenure',
          name: 'tenure',
          required: true
        },
        {
          type: 'text',
          label: 'Address',
          name: 'address',
          required: true
        },
        {
          type: 'text',
          label: 'State',
          name: 'state',
          required: true
        },
        {
          type: 'text',
          label: 'Pin Code',
          name: 'pinCode',
          required: true
        },
      ],
      submitData: {
        url: ['finance/application',2],
      },
      getData: {
        url: ['finance/application',2,'borrower'],
      },
      progress: {
        title: '1',
        description: 'Borrower Details',
      }
    },
    {
      inputs : [
        {
          type: 'text',
          label: 'Aadhaar Number',
          name: 'aadhaarNo',
          required: true
        },
        {
          type: 'file',
          label: 'Aadhaar Document',
          name: 'aadhaarDocument',
          required: true,
          onChangeType: 'upload'
        },
        {
          type: 'text',
          label: 'PAN Number',
          name: 'panNo',
          required: true
        },
        {
          type: 'file',
          label: 'PAN Document',
          name: 'panDocument',
          required: true
        },
        {
          type: 'text',
          label: 'First Name',
          name: 'firstName',
          required: true
        },
        {
          type: 'text',
          label: 'Last Name',
          name: 'lastName',
          required: true
        },
        {
          type: 'date',
          label: 'Date of Birth',
          name: 'dob',
          required: true
        },
        {
          type: 'text',
          label: 'Father Name',
          name: 'fatherName',
          required: true
        },
        {
          type: 'text',
          label: 'Address',
          name: 'address',
          required: true
        },
        {
          type: 'text',
          label: 'State',
          name: 'state',
          required: true
        },
        {
          type: 'text',
          label: 'Pin Code',
          name: 'pinCode',
          required: true
        },
      ],
      submitData: {
        url: ['finance/application',2],
      },
      getData: {
        url: ['finance/application',2,'guarantor'],
      },
      progress: {
        title: '2',
        description: 'Guarantor Details',
      }
    },
    {
      inputs : [
        {
          type: 'text',
          label: 'Aadhaar Number',
          name: 'aadhaarNo',
          required: true
        },
        {
          type: 'file',
          label: 'Aadhaar Document',
          name: 'aadhaarDocument',
          required: true
        },
        {
          type: 'text',
          label: 'PAN Number',
          name: 'panNo',
          required: true
        },
        {
          type: 'file',
          label: 'PAN Document',
          name: 'panDocument',
          required: true
        },
        {
          type: 'text',
          label: 'First Name',
          name: 'firstName',
          required: true
        },
        {
          type: 'text',
          label: 'Last Name',
          name: 'lastName',
          required: true
        },
        {
          type: 'date',
          label: 'Date of Birth',
          name: 'dob',
          required: true
        },
        {
          type: 'text',
          label: 'Father Name',
          name: 'fatherName',
          required: true
        },
        {
          type: 'text',
          label: 'Address',
          name: 'address',
          required: true
        },
        {
          type: 'text',
          label: 'State',
          name: 'state',
          required: true
        },
        {
          type: 'text',
          label: 'Pin Code',
          name: 'pinCode',
          required: true
        },
      ],
      submitData: {
        url: ['finance/application',2],
      },
      getData: {
        url: ['finance/application',2,'coborrower'],
      },
      progress: {
        title: '3',
        description: 'Co Borrower Details',
      }
    },
  ]
}*/

export {sampleJSON};