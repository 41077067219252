import { FC, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios';

const SignCallback: FC = () => {
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let client_id = params.get('client_id');
    let pathVars = window.location.pathname.split("/");
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/signcallback/' + client_id).then((data) => {
      if (data?.data?.data?.redirectUrl) {
        window.location.pathname = data?.data?.data?.redirectUrl;
      } else {
        //TODO
      }
    }).catch(() => {
      //TODO
    })
  }, [])
  return (<div className="text-center m-4">
    <img id="loader" src="https://complianceeasy.in/assets/images/preloader.gif"></img>
    <h4 className='text-center'>Wait while you are being redirected</h4>
  </div>)
}
export { SignCallback }