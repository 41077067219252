import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions, CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture';
import { VideoEditor, TranscodeOptions, VideoInfo } from '@ionic-native/video-editor';
import { call } from 'redux-saga/effects';
import { mediaFileToFile, mediaPathToFile } from './mediaFileToFile';
import { loaderDiv, removeLoaders } from '../modules/widgets/loader';
export const mediaCapture = (type: string, callback: Function, needGeoLocation? : boolean) => {
  if(needGeoLocation) {
    loaderDiv(document.body);
    navigator.geolocation.getCurrentPosition(function (geolocation : GeolocationPosition) {
      removeLoaders();
      capture(type, callback, [geolocation.coords.latitude, geolocation.coords.longitude]);
    },function () {
      console.log("no location")//TODO
    })
  } else {
    capture(type, callback);
  }
}
function capture(type : string, callback : Function, geoLocation? : number[]) {
  if (type == 'image') {
    let options: CaptureImageOptions = { limit: 1 };
    MediaCapture.captureImage(options).then(async (data: any) => {
      mediaFileToFile(data[0], function (blob: Blob) {
        if(geoLocation) {
          callback(blob, geoLocation, '.png');
        } else {
          callback(blob, null, '.png');
        }
      });
    }, (err: CaptureError) => {
      //TODO
    })
  }
  if (type == 'video') {
    let options: CaptureVideoOptions = { limit: 1, quality: 1, duration: 30 };
    MediaCapture.captureVideo(options).then((data: any) => {
      var videoFileName = 'videoCapture'; // I suggest a uuid
      var file = data[0];
      let transcodeOptions: TranscodeOptions = {
        fileUri: file.fullPath,
        outputFileName: videoFileName,
        outputFileType: VideoEditor.OutputFileType.MPEG4,
        optimizeForNetworkUse: VideoEditor.OptimizeForNetworkUse.YES,
        maintainAspectRatio: true,
        width: 640
      }
      loaderDiv(document.body)
      VideoEditor.transcodeVideo(transcodeOptions).then(function (outputFile) {
        mediaPathToFile("file://" + outputFile, function (blob: Blob) {
          if(geoLocation) {
            callback(blob, geoLocation, '.mp4');
          } else {
            callback(blob, null, '.mp4');
          }
        });
      }, function (error) {
        mediaFileToFile(data[0], function (blob: Blob) {
          if(geoLocation) {
            callback(blob, geoLocation, '.mp4');
          } else {
            callback(blob, null, '.mp4');
          }
        })
      })
      
    }, (err: CaptureError) => {
      //TODO
    })
  }
}