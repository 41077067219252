import axios, { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react'
import { Page, Input, HTML } from '../../modules/models/Page';
import { Formik, Form, FormikErrors, FormikTouched, Field, FormikValues } from 'formik';
import { fileUpload } from '../../utilities/fileUpload';
import { DocumentModal } from '../../modules/widgets/DocumentModal';
import { Link, useParams } from 'react-router-dom';
import { loaderDiv, removeLoaders } from '../../modules/widgets/loader';
import { resizeImage } from '../../utilities/resizeImage';
import { right } from '@popperjs/core';
import { Display } from '../../modules/widgets/Display';
let REACT_FINANCE_API_URL = "https://sandbox.complianceeasy.in/"

const Page1: FC = () => {
  type ApiParams = { section: string; };
  const { section } = useParams<ApiParams>();
  let [sectionIndex, setSectionIndex] = useState(-1);
  let [samplePage, setSamplePage] = useState(new Page());
  let [modalValues, setModalValues] = useState({ show: false, title: '', body: <></> });
  const display = (component: Input | HTML, values: FormikValues, disabled: boolean, errors?: FormikErrors<any>, touched?: FormikTouched<any>, setFieldValue?: Function, setErrors?: Function) => {
    return Display(samplePage, sectionIndex, updatePage, setModalValues, addRemark, component, values, disabled, errors, touched, setFieldValue, setErrors)
  }
  const addRemark = (component: Input, values?: FormikValues, setFieldValue?: Function) => {
    let remarkText = (document.getElementById('text' + component.name) as HTMLInputElement).value;
    if (remarkText && remarkText != '') {
      let remark = [{
        text: remarkText ? remarkText : '',
        id: samplePage.sections[sectionIndex].initValues.userId,
        name: samplePage.sections[sectionIndex].initValues.name
      }]
      let remarks = [...samplePage.sections[sectionIndex].initValues.remarks, ...remark];
      if (setFieldValue) {
        setFieldValue('remarks', remarks);
      }
      let newSamplePage = { ...samplePage } as Page;
      newSamplePage.sections[sectionIndex].initValues.remarks = remarks;
      setSamplePage(newSamplePage);
    }
  }
  const buttonSubmit = (values: FormikValues, updatedStatusOperation: number, validateForm: Function) => {
    validateForm();
    values.updatedStatusOperation = updatedStatusOperation;
    //onSubmit(values);
  }
  const onSubmit = (values: any) => {
    if (!(samplePage.editableStatus && samplePage.editableStatus.indexOf(samplePage.sections[sectionIndex].initValues.status) == -1)) {
      values.page = window.location.pathname.split('/')[1];
      loaderDiv(document.body);
      axios.post(process.env.REACT_APP_FINANCE_API_URL + samplePage.sections[sectionIndex].submitData.url, values, {
        headers: {
          'Content-Type': "application/json"
        }
      }).then((reply) => {//Errors come in status 200
        removeLoaders();
        if (sectionIndex < samplePage.sections.length - 1) {
          //setSectionIndex(sectionIndex + 1)
          let pathVars = window.location.pathname.split("/");
          window.location.pathname = pathVars[0] + '/' + pathVars[1] + '/' + pathVars[2] + '/' + (sectionIndex + 1);
        } else {
          window.location.pathname = '/dashboard'
        }
      }, (error) => {
        removeLoaders();
        //reject(error)//TODO
      });
    } else {
      if (sectionIndex < samplePage.sections.length - 1) {
        //setSectionIndex(sectionIndex + 1)
        let pathVars = window.location.pathname.split("/");
        window.location.pathname = pathVars[0] + '/' + pathVars[1] + '/' + pathVars[2] + '/' + (sectionIndex + 1);
      } else {
        window.location.pathname = '/dashboard'
      }
    }
  }
  const back = () => {
    let pathVars = window.location.pathname.split("/");
    let pathname = pathVars[0] + '/' + pathVars[1] + '/' + pathVars[2] + '/' + (sectionIndex - 1);
    window.location.pathname = pathname;
  }
  const updatePage = () => {
    axios.get(process.env.REACT_APP_FINANCE_API_URL + samplePage.sections[sectionIndex].getData.url).then((reply) => {//errors cone in status 200
      samplePage.sections[sectionIndex].initValues = reply.data.data; // exploiting initvalues
      let newSamplePage = { ...samplePage } as Page;
      newSamplePage.sections[sectionIndex] = samplePage.sections[sectionIndex];
      console.log(newSamplePage.sections[sectionIndex].initValues);
      setSamplePage(newSamplePage);
      removeLoaders();
    }, (error) => {
      //reject(error)//TODO
    });
  }
  useEffect(() => {
    if (sectionIndex > -1) {
      let pathVars = window.location.pathname.split("/");
      if (sectionIndex != parseInt(pathVars[pathVars.length - 1])) {
        window.location.pathname = pathVars[0] + '/' + pathVars[1] + '/' + pathVars[2] + '/' + sectionIndex;
      } else {
        loaderDiv(document.body);
        updatePage();
      }
    }
  }, [sectionIndex]);
  useEffect(() => {
    loaderDiv(document.body);
    axios.get(process.env.REACT_APP_FINANCE_API_URL + 'finance/page/' + window.location.pathname.split('/')[1]).then((reply) => {//errors cone in status 200
      if (reply?.data?.data?.pageJson) {
        setSamplePage(new Page(reply.data.data.pageJson));
        if (section) {
          setSectionIndex(parseInt(section));
        } else {
          setSectionIndex(0);
        }
      } else {
        throw "Error" //TODO
      }

      //updatePage();
    }, (error) => {
      //reject(error)//TODO
    });
  }, []);
  return (
    <div style={{ margin: "10px", background: "white", padding: "30px", paddingTop: "0px", paddingBottom: "50px", marginTop: "0px" }}>
      {!(samplePage && samplePage.visibleStatus && samplePage.sections && samplePage.sections[sectionIndex] && samplePage.visibleStatus.indexOf(samplePage.sections[sectionIndex].initValues.status) == -1) && <>
        <div className='text-center'>
          <div>
            {samplePage.sections.map((section, index) => {
              return (<div style={{ display: 'inline-block', margin: "10px" }} className="col-2">
                <span className='text-center' style={{ display: "inline-block", backgroundColor: index == sectionIndex ? "#23524A" : (index < sectionIndex ? "#66cc66" : "#dce0dc"), borderRadius: "50%", width: "30px", height: "30px", color: sectionIndex >= index ? "white" : "black", paddingTop: "6px" }}>{section.progress.title}</span><br />
              </div>)
            })}
          </div>
          <div>
            {samplePage.sections.map((section, index) => {
              return (<div style={{ display: 'inline-block', margin: "10px" }} className="col-2">
                {section.progress.description}
              </div>)
            })}
          </div>
        </div>
        {
          samplePage.sections.map((section, index) => {
            return (
              <>
                {index == sectionIndex &&
                  <Formik
                    enableReinitialize={true}
                    validationSchema={section.validationSchema}
                    initialValues={section.initValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={onSubmit}
                    key={index}
                  >
                    {({ errors, touched, values, setFieldValue, setErrors, validateForm }) =>
                      <Form className='form' noValidate id='kt_modal_create_app_form'>
                        {Object.keys(errors).length > 0 ? <div className='text-center text-danger p-2' style={{ fontSize: "16px" }}><b>Form has errors</b></div> : <></>}
                        {section.inputs.map((input) => {
                          return (
                            <div className='w-100' key={input.name}>
                              {display(input, values, (samplePage.editableStatus && samplePage.editableStatus.indexOf(section.initValues.status) == -1 ? true : false), errors, touched, setFieldValue, setErrors)}
                            </div>
                          )
                        })}
                        {samplePage.sections[sectionIndex].inputs.filter(e => { return e instanceof Input && e.type === 'accept' }).length > 0 &&
                          <div className='float-end'>
                            {
                              (samplePage.sections[sectionIndex].inputs.filter(e => { return e instanceof Input && e.type === 'accept' })[0] as Input).displayItems?.map((e, index) => {
                                if(e != '') {
                                  return(<button type="submit" className={'btn m-4 ' + (index == 0 ? 'btn-danger' : 'btn-success')} onClick={() => buttonSubmit(values, index, validateForm)} disabled={(samplePage.editableStatus && samplePage.editableStatus.indexOf(section.initValues.status) == -1 ? true : false)}>{e}</button>)
                                } else {
                                  return(<></>)
                                }
                              })
                            }
                          </div>}
                        {sectionIndex != 0 && <button type='button' className='btn btn-lg btn-primary me-3' onClick={back}>Back</button>}
                        {samplePage.sections[sectionIndex].inputs.filter(e => { return e instanceof Input && e.type === 'accept' }).length == 0 &&
                          <button type='submit' className='btn btn-lg btn-primary me-3 float-end' style={{ textAlign: "right" }} onClick={() => { window.scrollTo(0, 0) }}>{sectionIndex == (samplePage.sections.length - 1) ? 'Submit' : 'Next'}</button>}
                        
                      </Form>
                    }
                  </Formik>}
                {DocumentModal(modalValues, setModalValues)}
              </>
            )
          })
        }
      </>}
      {
        (samplePage && samplePage.visibleStatus && samplePage.sections && samplePage.sections[sectionIndex] && samplePage.visibleStatus.indexOf(samplePage.sections[sectionIndex].initValues.status) == -1) &&
        <div className='text-center mt-7 pt-7'>
          <h5>Document has NOT reached this stage yet.</h5>
        </div>
      }
    </div>
  )
}
export { Page1 }