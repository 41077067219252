import * as Yup from 'yup';
import { sampleJSON } from './SamplePage';
export class Section {
  inputs: (Input | HTML)[];
  getData: GetData;
  submitData: SubmitData;
  progress: Progress;
  constructor(inputJSON: any) {
    if (inputJSON.inputs && inputJSON.submitData && inputJSON.getData && inputJSON.progress) {//Type checks
      this.inputs = inputJSON.inputs.map((input: any) => {
        if (input.type) {
          return new Input(input, this)
        }
        if (input.html) {
          return new HTML(input)
        }
      });
      this.submitData = new SubmitData(inputJSON.submitData);
      this.getData = new GetData(inputJSON.getData);
      this.progress = new Progress(inputJSON.progress);
    } else {
      throw "error";//TODO
    }
  }
  validationSchema = () => {
    let validationObject: any = {};
    this.inputs.map((input) => {
      if (input instanceof Input) {
        if (input.type == 'file' || input.type == 'takepicture' || input.type == 'takevideo') {
          let validYup = Yup.mixed().label(input.label);
          if (input.required) {
            validYup = validYup.test(
              "fileSize",
              input.label + " is required",
              () => {
                let htmlElement: any = document.getElementById(input.name);
                return (this.initValues[input.name] && this.initValues[input.name] != '')
              }
            )
          }
          validationObject[input.name] = validYup;
        }
        if(input.type == 'remarks') {
          if (input.required) {
            let validYup = Yup.array().required().min(1, "Remarks Required")
            validationObject[input.name] = validYup;
          }
        }
        if (input.type == 'text' || input.type == 'date') {
          let validYup = Yup.string().label(input.label);
          if (input.required) {
            validYup = validYup.required();
          }
          if (input.format) {
            validYup = validYup.matches(new RegExp(input.format), "Incorrect format");
          }
          validationObject[input.name] = validYup;
        }
      }
    })
    return Yup.object(validationObject);
  }
  initValues: any = () => {
    let initialValues: any = {};
    this.inputs.map((input) => {
      if (input instanceof Input && input.initialValue) initialValues[input.name] = input.initialValue;
    })
    return initialValues;
  }
}
export class HTML {
  name: string;//How to enforce
  html: string;//Will this work?
  constructor(inputJSON: any) {
    if (inputJSON.html && inputJSON.name) {//Type checks
      this.html = inputJSON.html;
      this.name = inputJSON.name;
    } else {
      throw "error";//TODO
    }
  }
}
export class Input {
  type: string;
  label: string;
  name: string;
  required: boolean;
  displayItems?: string[];
  format?: any; //YUP
  placeholder?: string;
  initialValue?: string;
  onChangeType?: string;
  parentSection: Section;
  constructor(inputJSON: any, parentSection: Section) {
    if (inputJSON.type && inputJSON.label && inputJSON.name && inputJSON.required != null) {//Type checks
      this.type = inputJSON.type;
      this.label = inputJSON.label;
      this.name = inputJSON.name;
      this.required = inputJSON.required;
      this.parentSection = parentSection;
      if (inputJSON.format) {
        this.format = inputJSON.format;
      }
      if (inputJSON.placeholder) {
        this.placeholder = inputJSON.placeholder;
      }
      if (inputJSON.initialValue) {
        this.initialValue = inputJSON.initialValue;
      }
      if (inputJSON.onChangeType) {
        this.onChangeType = inputJSON.onChangeType;
      }
      if(inputJSON.displayItems) {
        this.displayItems = inputJSON.displayItems;
      }
    } else {
      throw "error" //TODO
    }
  }
}
class SubmitData {
  url: string;
  contentType?: string;
  constructor(inputJSON: any) {
    if (inputJSON.url) {//Type checks
      this.url = inputJSON.url.map((element : any) => {
        if(!isNaN(element)) {
          return window.location.pathname.split('/')[element];
        } else {
          return element;
        }
      }).join('/');
    } else {
      throw "error" //TODO
    }
    if (inputJSON.contentType) {
      this.contentType = inputJSON.contentType;
    }
  }
}
class GetData {
  url: string;
  constructor(inputJSON: any) {
    if (inputJSON.url) {//Type checks
      this.url = inputJSON.url.map((element : any) => {
        if(!isNaN(element)) {
          return window.location.pathname.split('/')[element];
        } else {
          return element;
        }
      }).join('/');
    } else {
      throw "error" //TODO
    }
  }
}
class Progress {
  title: string;
  description: string;
  constructor(inputJSON: any) {
    if (inputJSON.title && inputJSON.description) {//Type checks
      this.title = inputJSON.title;
      this.description = inputJSON.description;
    } else {
      throw "error" //TODO
    }
  }
}
export class Page {
  sections: Section[];
  editableStatus?: number[];
  visibleStatus?: number[];
  theme?: string;
  loader?: boolean;
  title?: string;
  favicon?: string;
  constructor(inputJSON?: any) {
    if(!inputJSON) {
      inputJSON = sampleJSON;
    }
    if ((inputJSON.sections && Array.isArray(inputJSON.sections))) {
      this.sections = inputJSON.sections.map((section: any) => {
        return new Section(section);
      })
    } else {
      throw 'error';//TODO
    }
    this.editableStatus = inputJSON.editableStatus ? inputJSON.editableStatus : '';
    this.visibleStatus = inputJSON.visibleStatus ? inputJSON.visibleStatus : '';
    this.theme = inputJSON.theme ? inputJSON.theme : '';
  }
  updateSection(section: any, sectionNo: number) {
    if (!this.sections[sectionNo]) throw 'error';//TODO
    this.sections[sectionNo] = new Section(section);
  }
}