/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { useParams, useLocation } from "react-router-dom";
import clsx from 'clsx';
import axios, { AxiosResponse } from 'axios'
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { MasterLayout } from '../../../_metronic/layout/MasterLayout';
import { loaderDiv, removeLoaders } from '../widgets/loader';

const ApiPage: FC = () => {
  type ApiParams = {
    api: string;
    type: string;
  };
  const [loading, setLoading] = useState(false);
  const [turn, setTurn] = useState(1);
  const [isMock, setIsMock] = useState(false);
  const [responseFormatJSON, setResponseFormatJSON] = useState(false);
  const [response, setResponse] = useState<ResponseMap>({});
  const [responseKeys, setResponseKeys] = useState<string[]>([]);
  const { api, type } = useParams<ApiParams>();
  const [currentSchema, setCurrentSchema] = useState<any>(Yup.object({}))
  const location = useLocation();
  type ValidationObject = { [key: string]: any; }
  type GenericObject = { [key: string]: string; }
  let initialValues: GenericObject = {};
  type InputDetailsL1 = {
    [key: string]: InputDetailsL2;
  };
  type InputDetailsL2 = {
    [key: string]: InputDetailsL3[];
  }
  type InputDetailsL3 = {
    type: string;
    label: string;
    name: string;
    format: any;
    turn: number;
  }
  let inputDetails: InputDetailsL1 = {
    'identity': {
      'pan': [
        {
          type: 'text',
          label: 'PAN Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number')
            .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Incorrect Format"),
          turn: 1,
        }
      ],
      'passport': [
        {
          type: 'text',
          label: 'Passport Application Number',
          name: 'file_number',
          format: Yup.string().required().label('Passport'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'Date of Birth(yyyy-mm-dd)',
          name: 'dob',
          format: Yup.string().required().label('Date of Birth'),
          turn: 1,
        },
      ],
      'voter-id': [
        {
          type: 'text',
          label: 'Voter ID number',
          name: 'id_number',
          format: Yup.string().required().label('Voter Id'),
          turn: 1,
        }
      ],
      'driving-license': [
        {
          type: 'text',
          label: 'Driving License',
          name: 'id_number',
          format: Yup.string().required().label('Driving License'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'Date of Birth(yyyy-mm-dd)',
          name: 'dob',
          format: Yup.string().required().label('Date of Birth'),
          turn: 1,
        }
      ],
      'aadhaar': [
        {
          type: 'text',
          label: 'Aadhaar Number',
          name: 'id_number',
          format: Yup.string().required().label('Aadhaar Number'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'OTP',
          name: 'otp',
          format: Yup.string(),
          turn: 2,
        },
      ],
    },
    'bank': {
      'bank-verification': [
        {
          type: 'text',
          label: 'Account Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'IFSC Code',
          name: 'ifsc',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        }
      ]
    },
    'esign': {
      'aadhaar': [
        {
          type: 'text',
          label: 'Name',
          name: 'name',
          format: Yup.string().required().label('Aadhaar Number'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'Phone no.',
          name: 'phone',
          format: Yup.string().required().label('Phone Number'),
          turn: 1,
        },
        {
          type: 'text',
          label: 'Email',
          name: 'email',
          format: Yup.string().required().label('Email'),
          turn: 1,
        },
        {
          type: 'file',
          label: 'Upload Document',
          name: 'file',
          format: Yup.string().required().label('Aadhaar Document'),
          turn: 1,
        },
      ]
    },
    'kyb': {
      'gstin-by-pan': [
        {
          type: 'text',
          label: 'Pan Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'gstin': [
        {
          type: 'text',
          label: 'GSTIN Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'din': [
        {
          type: 'text',
          label: 'DIN Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'udyog-aadhaar': [
        {
          type: 'text',
          label: 'Udyog Aadhar Number',
          name: 'id_number',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ]
    },
    'ocr': {
      'pan': [
        {
          type: 'file',
          label: 'Upload PAN Card',
          name: 'file',
          format: Yup.mixed(),
          turn: 1,
        }
      ],
      'aadhaar': [
        {
          type: 'file',
          label: 'Upload Aadhaar',
          name: 'file',
          format: Yup.mixed(),
          turn: 1,
        },
      ],
      'license': [
        {
          type: 'file',
          label: 'Upload License',
          name: 'file',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'passport': [
        {
          type: 'file',
          label: 'Upload Passport',
          name: 'file',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'itr-v': [
        {
          type: 'file',
          label: 'Upload ITR',
          name: 'file',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
      'voter': [
        {
          type: 'file',
          label: 'Upload Voter ID',
          name: 'file',
          format: Yup.string().required().label('PAN Number'),
          turn: 1,
        },
      ],
    }
  };
  type APIMap = {
    [key: string]: {
      [key: string]: string
    };
  }
  let apiMap: APIMap = {
    'identity': {
      'aadhaar': 'aadhaar-v2/generate-otp',
      'pan': 'pan/pan',
      'passport': 'passport/passport/passport-details',
      'voter-id': 'voter-id/voter-id',
      'driving-license': 'driving-license/driving-license',
    },
    'bank': {
      'bank-verification': 'bank-verification',
    },
    'esign': {
      'aadhaar': 'esign/aadhaar',
    },
    'ocr': {
      'aadhaar': 'ocr/aadhaar',
      'pan': 'ocr/pan',
      'license': 'ocr/license',
      'passport': 'ocr/passport',
      'itr-v': 'ocr/itr-v',
      'voter': 'ocr/voter',
    },
    'kyb': {
      'gstin-by-pan': 'corporate/gstin-by-pan',
      'gstin': 'corporate/gstin',
      'din': 'corporate/din',
      'udyog-aadhaar': 'corporate/udyog-aadhaar',
    }
  }
  type ResponseMap = {
    [key: string]: string;
  }
  let responseMap: ResponseMap = {
    'pan_number': 'PAN Number',
    'full_name': 'Full Name',
    'state': 'State',
    'gender': 'Gender',
    'account_number': 'Account Number',
    'name': 'Name',
    'house_no': 'House Number',
    'dob': 'Date of Birth',
    'area': 'Area',
    'assembly_constituency': 'Assembly Constituency',
    'polling_station': 'Polling Station',
    'temporary_address': 'Temporary Address',
    'license_number': 'license_number',
    'ola_name': 'ola_name',
    'date_of_application': 'date of application',
    'file_number': 'file_number',
    'passport_number': 'passport number',
    'father_or_husband_name': 'Father/Husband Name'
  }
  const formik = useFormik({
    validationSchema: currentSchema,
    initialValues,
    onSubmit: (values) => {
      loaderDiv(document.body);
      setResponse({});
      if (turn == 1) handleTurn1Request(values);
      if (turn == 2) handleTurn2Request(values);
      function handleTurn1Request(values: ResponseMap) {
        let isInputFile = inputDetails[api][type].filter((inputDetail) => { return inputDetail['type'] == 'file' }).length > 0;
        if (isInputFile) {

          let formData = new FormData();
          for (let i = 0; i < inputDetails[api][type].length; i++) {
            if (inputDetails[api][type][i]['type'] == 'file') {
              let input: any = document.getElementById(inputDetails[api][type][i]['name']);
              let file = input.files[0];
              formData.append(inputDetails[api][type][i]['name'], file);
            }
            if (inputDetails[api][type][i]['type'] == 'text') {
              formData.append(inputDetails[api][type][i]['name'], values[inputDetails[api][type][i]['name']]);
            }
          }
          axios.post(process.env.REACT_APP_API_URL + (isMock ? 'mock/' : 'v1/') + apiMap[api][type], formData, {
            headers: {
              'Content-Type': "application/x-www-form-urlencoded"
            }
          }).then((reply) => {
            removeLoaders();
            handleResponse(reply);
          }, (error) => {
            removeLoaders();
            console.log(error);
          });
        } else {
          axios.post(process.env.REACT_APP_API_URL + (isMock ? 'mock/' : 'v1/') + apiMap[api][type], values).then((reply) => {
            removeLoaders();
            handleResponse(reply);
          }, (error) => {
            removeLoaders();
            console.log(error);
          });
        }
      }
      function handleTurn2Request(values: ResponseMap) {
        if (api == 'identity' && type == 'aadhaar') {
          let input = {
            otp: values.otp,
            client_id: response.client_id,
          }
          axios.post(process.env.REACT_APP_API_URL + 'v1/aadhaar-v2/submit-otp', input).then((reply) => {
            removeLoaders();
            handleResponse(reply);
          }, (error) => {
            removeLoaders();
            console.log(error);
          });
        }
      }
      function handleResponse(reply: AxiosResponse) {
        let hasTurn2 = inputDetails[api][type].filter((inputDetail) => { return inputDetail['turn'] == 2 }).length > 0;
        if (turn == 1 && hasTurn2) {
          setTurn(2);
        } else {
          setTurn(1);
        }
        if (reply.data.popupUrl) {
          window.open(reply.data.popupUrl, '_blank');
        } else {
          if (api == 'ocr') {
            let data : ResponseMap = {};
            Object.keys(reply.data.data.ocr_fields[0]).forEach((k : any) => {
              if(reply.data.data.ocr_fields[0][k] && reply.data.data.ocr_fields[0][k].value) {
                data[k] = reply.data.data.ocr_fields[0][k].value;
              }
            });
            setResponse(data);
            setResponseKeys(Object.keys(data))
          } else {
            setResponse(reply.data.data);
            setResponseKeys(Object.keys(reply.data.data))
          }
        }
      }
    },
  })
  useEffect(() => {
    let validationObject: ValidationObject = {}
    inputDetails[api][type].forEach((input) => {
      validationObject[input['name']] = input['format'];
    })
    setCurrentSchema(Yup.object(validationObject));
    inputDetails[api][type].forEach((input) => {
      if (document.getElementById(input['name']) !== null) {
        console.log((document.getElementById(input['name']) as HTMLInputElement).value);
        (document.getElementById(input['name']) as HTMLInputElement).value = "";
      }
    })
    formik.resetForm();
    setResponseKeys([]);
  }, [location]);
  return (
    <MasterLayout>
      <div style={{ background: 'white' }}>
        <form onSubmit={formik.handleSubmit} className='form w-100' noValidate id='kt_login_signin_form' style={{ padding: "30px" }}>
          {/* begin::Heading */}
          <input onChange={(e) => { setIsMock(e.target.checked); }} data-switch="true" type="checkbox" data-on-text="Table" data-handle-width="50" data-off-text="JSON" data-on-color="success" />Mock
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{type.toUpperCase()} - {api.toUpperCase()}</h1>
          </div>
          {/* begin::Heading */}

          {/* begin::Form group */}
          {inputDetails[api][type].map((inputDetail) => {
            return (
              <div>
                {inputDetail['turn'] == turn &&
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder text-dark'>{inputDetail['label']}</label>
                    {inputDetail['type'] != 'file' && <input placeholder={inputDetail['label']} className={clsx('form-control form-control-lg form-control-solid',)}
                      type={inputDetail['type']} autoComplete='off' {...formik.getFieldProps(inputDetail['name'])} id={inputDetail['name']} />}
                    {inputDetail['type'] == 'file' && <input placeholder={inputDetail['label']} className={clsx('form-control form-control-lg form-control-solid',)}
                      type={inputDetail['type']} autoComplete='off' id={inputDetail['name']} />}
                    {formik.errors[inputDetail['name']] && formik.errors[inputDetail['name']] != '' &&
                      <div className='text-danger'>*{formik.errors[inputDetail['name']]}</div>}
                  </div>
                }
              </div>
            )
          })}
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-100 mb-5'>
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
        {responseKeys.length > 0 && <div style={{ padding: "30px", background: "#f5f5f5", margin: "37px", marginBottom: "10px" }}>
          <h3 className='' style={{ marginBottom: "30px" }}>Response</h3>
          <input onChange={(e) => { setResponseFormatJSON(e.target.checked); }} data-switch="true" type="checkbox" data-on-text="Table" data-handle-width="50" data-off-text="JSON" data-on-color="success" />JSON
          {responseFormatJSON && <pre id="json" style={{ fontSize: "16px", background: "#000000", color: "#FFFFFF" }}>{JSON.stringify(response, null, 2)}</pre>}
          {!responseFormatJSON && <table className='table table-bordered' style={{ marginTop: "10px", background: "#000000", color: "#FFFFFF" }}>
            <thead>
              <tr><th>Key</th><th>Value</th></tr>
            </thead>
            <tbody>
              {responseKeys.map((row) => {
                return (responseMap[row] ? <tr>
                  <td>{responseMap[row]}</td><td>{response[row]}</td>
                </tr>
                  : <></>)
              })}
            </tbody>
          </table>}
        </div>}
      </div>
    </MasterLayout>
  )
}

export { ApiPage }
